import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/components/HomePage'
import PageHome from '@/components/PageHome'
import ProductCenter from '@/components/ProductCenter'
import joinUs from '@/components/joinUs'
import aboutUs from '@/components/aboutUs'
import soluTion from '@/components/soluTion'
import serviceSupport from '@/components/serviceSupport'
import DoHome from '@/components/DoHome.vue';
import mmWaveRadarComponent from '@/components/mmWaveRadarComponent.vue';

import civilianProducts from '@/components/civilianProducts.vue';


import justGo from '@/components/justGo.vue';
import joinGo from '@/components/joinGo.vue';


import viewsCenter from '@/components/viewsCenter.vue';
import separateNews1 from '@/components/newView/separateNews1.vue';
import separateNews2 from '@/components/newView/separateNews2.vue';
import separateNews3 from '@/components/newView/separateNews3.vue';
import separateNews4 from '@/components/newView/separateNews4.vue';
import separateNews5 from '@/components/newView/separateNews5.vue';
import separateNews6 from '@/components/newView/separateNews6.vue';
import separateNews7 from '@/components/newView/separateNews7.vue';
import separateNews8 from '@/components/newView/separateNews8.vue';
import separateNews9 from '@/components/newView/separateNews9.vue';
import separateNews10 from '@/components/newView/separateNews10.vue';
import separateNews11 from '@/components/newView/separateNews11.vue';
import separateNews12 from '@/components/newView/separateNews12.vue';
import separateNews13 from '@/components/newView/separateNews13.vue';
import separateNews14 from '@/components/newView/separateNews14.vue';
import separateNews15 from '@/components/newView/separateNews15.vue';
import separateNews16 from '@/components/newView/separateNews16.vue';
import separateNews17 from '@/components/newView/separateNews17.vue';
import separateNews18 from '@/components/newView/separateNews18.vue';
import separateNews19 from '@/components/newView/separateNews19.vue';
import separateNews20 from '@/components/newView/separateNews20.vue';
import separateNews21 from '@/components/newView/separateNews21.vue';
import separateNews22 from '@/components/newView/separateNews22.vue';
import separateNews23 from '@/components/newView/separateNews23.vue';
import separateNews24 from '@/components/newView/separateNews24.vue';
import separateNews25 from '@/components/newView/separateNews25.vue';


import newsCentres from '@/components/newsCentres.vue';


import mationBan1 from '@/components/proDuc/mationBan1.vue';
import mationBan2 from '@/components/proDuc/mationBan2.vue';
import mationBan3 from '@/components/proDuc/mationBan3.vue';
import mationBan4 from '@/components/proDuc/mationBan4.vue';
import mationBan5 from '@/components/proDuc/mationBan5.vue';
import mationBan6 from '@/components/proDuc/mationBan6.vue';
import mationBan7 from '@/components/proDuc/mationBan7.vue';
import mationBan8 from '@/components/proDuc/mationBan8.vue';
import mationBan9 from '@/components/proDuc/mationBan9.vue';
import mationBan10 from '@/components/proDuc/mationBan10.vue';
import mationBan11 from '@/components/proDuc/mationBan11.vue';
import mationBan12 from '@/components/proDuc/mationBan12.vue';
import mationBan13 from '@/components/proDuc/mationBan13.vue';
import mationBan14 from '@/components/proDuc/mationBan14.vue';
import mationBan15 from '@/components/proDuc/mationBan15.vue';
import mationBan16 from '@/components/proDuc/mationBan16.vue';
import ledDisplay1 from '@/components/proDuc/ledDisplay1.vue';
import ledDisplay2 from '@/components/proDuc/ledDisplay2.vue';
import ledDisplay3 from '@/components/proDuc/ledDisplay3.vue';
import ledDisplay4 from '@/components/proDuc/ledDisplay4.vue';
import ledDisplay5 from '@/components/proDuc/ledDisplay5.vue';
import ledDisplay6 from '@/components/proDuc/ledDisplay6.vue';
import ledDisplay7 from '@/components/proDuc/ledDisplay7.vue';
import ledDisplay8 from '@/components/proDuc/ledDisplay8.vue';
import ledDisplay9 from '@/components/proDuc/ledDisplay9.vue';
import ledDisplay10 from '@/components/proDuc/ledDisplay10.vue';
import ledDisplay11 from '@/components/proDuc/ledDisplay11.vue';
import ledDisplay12 from '@/components/proDuc/ledDisplay12.vue';
import ledLighting1 from '@/components/proDuc/ledLighting1.vue';
import ledLighting2 from '@/components/proDuc/ledLighting2.vue';
import ledLighting3 from '@/components/proDuc/ledLighting3.vue';
import ledLighting4 from '@/components/proDuc/ledLighting4.vue';
import ledLighting5 from '@/components/proDuc/ledLighting5.vue';

import millimeterWaveRadar1 from '@/components/proDuc/millimeterWaveRadar1.vue';
import millimeterWaveRadar2 from '@/components/proDuc/millimeterWaveRadar2.vue';
import millimeterWaveRadar3 from '@/components/proDuc/millimeterWaveRadar3.vue';
import millimeterWaveRadar4 from '@/components/proDuc/millimeterWaveRadar4.vue';
import millimeterWaveRadar5 from '@/components/proDuc/millimeterWaveRadar5.vue';
import millimeterWaveRadar6 from '@/components/proDuc/millimeterWaveRadar6.vue';
import millimeterWaveRadar7 from '@/components/proDuc/millimeterWaveRadar7.vue';
import millimeterWaveRadar8 from '@/components/proDuc/millimeterWaveRadar8.vue';
import millimeterWaveRadar9 from '@/components/proDuc/millimeterWaveRadar9.vue';

import civilianProducts1 from '@/components/proDuc/civilianProducts1.vue';
import civilianProducts2 from '@/components/proDuc/civilianProducts2.vue';
import civilianProducts3 from '@/components/proDuc/civilianProducts3.vue';
import civilianProducts4 from '@/components/proDuc/civilianProducts4.vue';
import civilianProducts5 from '@/components/proDuc/civilianProducts5.vue';
import civilianProducts6 from '@/components/proDuc/civilianProducts6.vue';
import civilianProducts7 from '@/components/proDuc/civilianProducts7.vue';
import civilianProducts8 from '@/components/proDuc/civilianProducts8.vue';
import civilianProducts9 from '@/components/proDuc/civilianProducts9.vue';
import civilianProducts10 from '@/components/proDuc/civilianProducts10.vue';





import inTrans1 from '@/components/proDuc/inTrans1.vue';
import inTrans2 from '@/components/proDuc/inTrans2.vue';
import inTrans3 from '@/components/proDuc/inTrans3.vue';
import inTrans4 from '@/components/proDuc/inTrans4.vue';
import inTrans5 from '@/components/proDuc/inTrans5.vue';
import inTrans6 from '@/components/proDuc/inTrans6.vue';
import inTrans7 from '@/components/proDuc/inTrans7.vue';
import inTrans8 from '@/components/proDuc/inTrans8.vue';
import inTrans9 from '@/components/proDuc/inTrans9.vue';

import soTion from '@/components/soTion.vue';

import samCov from '@/components/soLu/samCov.vue';
import samCov3 from '@/components/soLu/samCov3.vue';
import samCov1 from '@/components/soLu/samCov1.vue';
import samCov2 from '@/components/soLu/samCov2.vue';
import samCov4 from '@/components/soLu/samCov4.vue';
import samCov5 from '@/components/soLu/samCov5.vue';
import samCov6 from '@/components/soLu/samCov6.vue';
import samCov7 from '@/components/soLu/samCov7.vue';
import samCov8 from '@/components/soLu/samCov8.vue';
import samCov9 from '@/components/soLu/samCov9.vue';
import samCov10 from '@/components/soLu/samCov10.vue';
import samCov11 from '@/components/soLu/samCov11.vue';
import samCov12 from '@/components/soLu/samCov12.vue';
import samCov13 from '@/components/soLu/samCov13.vue';
import samCov14 from '@/components/soLu/samCov14.vue';
import samCov15 from '@/components/soLu/samCov15.vue';
import samCov16 from '@/components/soLu/samCov16.vue';
import samCov17 from '@/components/soLu/samCov17.vue';
import samCov18 from '@/components/soLu/samCov18.vue';
import samCov19 from '@/components/soLu/samCov19.vue';
import samCov20 from '@/components/soLu/samCov20.vue';




import newsBenes from '@/components/newsBenes.vue';

import casePresentation from '@/components/casePresentation.vue';
import casePagination1 from '@/components/caseMl/casePagination1.vue';
import casePagination2 from '@/components/caseMl/casePagination2.vue';
import casePagination3 from '@/components/caseMl/casePagination3.vue';
import casePagination4 from '@/components/caseMl/casePagination4.vue';
import casePagination5 from '@/components/caseMl/casePagination5.vue';
import casePagination6 from '@/components/caseMl/casePagination6.vue';
import casePagination7 from '@/components/caseMl/casePagination7.vue';
import casePagination9 from '@/components/caseMl/casePagination9.vue';



import casePagination10 from '@/components/caseMl/casePagination10.vue';
import casePagination11 from '@/components/caseMl/casePagination11.vue';

import casePagination12 from '@/components/caseMl/casePagination12.vue';
import casePagination13 from '@/components/caseMl/casePagination13.vue';
import casePagination14 from '@/components/caseMl/casePagination14.vue';
import casePagination15 from '@/components/caseMl/casePagination15.vue';
import casePagination65 from '@/components/caseMl/casePagination65.vue';



import casePagination16 from '@/components/caseMl/casePagination16.vue';
import casePagination17 from '@/components/caseMl/casePagination17.vue';
import casePagination18 from '@/components/caseMl/casePagination18.vue';
import casePagination19 from '@/components/caseMl/casePagination19.vue';
import casePagination20 from '@/components/caseMl/casePagination20.vue';
import casePagination21 from '@/components/caseMl/casePagination21.vue';
import casePagination22 from '@/components/caseMl/casePagination22.vue';
import casePagination68 from '@/components/caseMl/casePagination68.vue';
import casePagination23 from '@/components/caseMl/casePagination23.vue';
import casePagination24 from '@/components/caseMl/casePagination24.vue';
import casePagination25 from '@/components/caseMl/casePagination25.vue';

import casePagination26 from '@/components/caseMl/casePagination26.vue';
import casePagination27 from '@/components/caseMl/casePagination27.vue';
import casePagination28 from '@/components/caseMl/casePagination28.vue';
import casePagination29 from '@/components/caseMl/casePagination29.vue';

import casePagination30 from '@/components/caseMl/casePagination30.vue';
import casePagination31 from '@/components/caseMl/casePagination31.vue';
import casePagination32 from '@/components/caseMl/casePagination32.vue';
import casePagination33 from '@/components/caseMl/casePagination33.vue';

import casePagination34 from '@/components/caseMl/casePagination34.vue';
import casePagination35 from '@/components/caseMl/casePagination35.vue';
import casePagination36 from '@/components/caseMl/casePagination36.vue';
import casePagination37 from '@/components/caseMl/casePagination37.vue';

import casePagination38 from '@/components/caseMl/casePagination38.vue';
import casePagination39 from '@/components/caseMl/casePagination39.vue';
import casePagination40 from '@/components/caseMl/casePagination40.vue';
import casePagination41 from '@/components/caseMl/casePagination41.vue';

import casePagination42 from '@/components/caseMl/casePagination42.vue';
import casePagination43 from '@/components/caseMl/casePagination43.vue';
import casePagination44 from '@/components/caseMl/casePagination44.vue';
import casePagination45 from '@/components/caseMl/casePagination45.vue';

import casePagination46 from '@/components/caseMl/casePagination46.vue';
import casePagination47 from '@/components/caseMl/casePagination47.vue';
import casePagination48 from '@/components/caseMl/casePagination48.vue';
import casePagination49 from '@/components/caseMl/casePagination49.vue';
import casePagination50 from '@/components/caseMl/casePagination50.vue';
import casePagination51 from '@/components/caseMl/casePagination51.vue';
import casePagination52 from '@/components/caseMl/casePagination52.vue';
import casePagination53 from '@/components/caseMl/casePagination53.vue';
import casePagination54 from '@/components/caseMl/casePagination54.vue';
import casePagination55 from '@/components/caseMl/casePagination55.vue';

import casePagination56 from '@/components/caseMl/casePagination56.vue';
import casePagination57 from '@/components/caseMl/casePagination57.vue';
import casePagination58 from '@/components/caseMl/casePagination58.vue';
import casePagination59 from '@/components/caseMl/casePagination59.vue';

import casePagination60 from '@/components/caseMl/casePagination60.vue';
import casePagination61 from '@/components/caseMl/casePagination61.vue';
import casePagination62 from '@/components/caseMl/casePagination62.vue';
import casePagination63 from '@/components/caseMl/casePagination63.vue';
import casePagination64 from '@/components/caseMl/casePagination64.vue';

import casePagination66 from '@/components/caseMl/casePagination66.vue';

import caseLink from '@/components/caseLink.vue';







Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
      children:[
        {
          path: '/',
          name: 'PageHome',
          component: PageHome
        },

        {
          path: '/casePresentation',
          name: 'casePresentation',
          component: casePresentation,
          children:[

            {
              path: '/',
              name: 'caseLink',
              component: caseLink,
            },

            {
              path: '/casePagination1',
              name: 'casePagination1',
              component: casePagination1,
            },

            {
              path: '/casePagination2',
              name: 'casePagination2',
              component: casePagination2,
            }

            ,

             {
              path: '/casePagination3',
              name: 'casePagination3',
              component: casePagination3,
             }

            , 

            {
              path: '/casePagination4',
              name: 'casePagination4',
              component: casePagination4,
            }
            ,
            
            {
              path: '/casePagination5',
              name: 'casePagination5',
              component: casePagination5,
            }
            , 
            
            {
              path: '/casePagination6',
              name: 'casePagination6',
              component: casePagination6,
            }
            , {
              path: '/casePagination7',
              name: 'casePagination7',
              component: casePagination7,
            }
            , {
              path: 'casePagination65',
              name: 'casePagination65',
              component: casePagination65,
            }, 
            {
              path: 'casePagination9',
              name: 'casePagination9',
              component: casePagination9,
            }
            , {
              path: '/casePagination10',
              name: 'casePagination10',
              component: casePagination10,
            }
            , {
              path: '/casePagination11',
              name: 'casePagination11',
              component: casePagination11,
            }

            , {
              path: '/casePagination12',
              name: 'casePagination12',
              component: casePagination12,
            }
            , {
              path: '/casePagination13',
              name: 'casePagination13',
              component: casePagination13,
            }
            , {
              path: '/casePagination14',
              name: 'casePagination14',
              component: casePagination14,
            }
            , {
              path: '/casePagination15',
              name: 'casePagination15',
              component: casePagination15,
            }

            , {
              path: '/casePagination16',
              name: 'casePagination16',
              component: casePagination16,
            }
            , {
              path: '/casePagination17',
              name: 'casePagination17',
              component: casePagination17,
            }
            , {
              path: '/casePagination18',
              name: 'casePagination18',
              component: casePagination18,
            }
            , {
              path: '/casePagination19',
              name: 'casePagination19',
              component: casePagination19,
            }
            , {
              path: '/casePagination20',
              name: 'casePagination20',
              component: casePagination20,
            } , {
              path: '/casePagination21',
              name: 'casePagination21',
              component: casePagination21,
            }
            , {
              path: '/casePagination22',
              name: 'casePagination22',
              component: casePagination22,
            }
            , {
              path: '/casePagination23',
              name: 'casePagination23',
              component: casePagination23,
            }
            , {
              path: '/casePagination24',
              name: 'casePagination24',
              component: casePagination24,
            }
            , {
              path: '/casePagination25',
              name: 'casePagination25',
              component: casePagination25,
            }
            , {
              path: '/casePagination26',
              name: 'casePagination26',
              component: casePagination26,
            }

            , {
              path: '/casePagination27',
              name: 'casePagination27',
              component: casePagination27,
            }
            , {
              path: '/casePagination28',
              name: 'casePagination28',
              component: casePagination28,
            }
            , {
              path: '/casePagination29',
              name: 'casePagination29',
              component: casePagination29,
            }
            , {
              path: '/casePagination30',
              name: 'casePagination30',
              component: casePagination30,
            }

            , {
              path: '/casePagination31',
              name: 'casePagination31',
              component: casePagination31,
            }
            , {
              path: '/casePagination32',
              name: 'casePagination32',
              component: casePagination32,
            }
            , {
              path: '/casePagination33',
              name: 'casePagination33',
              component: casePagination33,
            }
            , {
              path: '/casePagination34',
              name: 'casePagination34',
              component: casePagination34,
            }
            , {
              path: '/casePagination35',
              name: 'casePagination35',
              component: casePagination35,
            }

             , {
              path: '/casePagination36',
              name: 'casePagination36',
              component: casePagination36,
            }
            , {
              path: '/casePagination37',
              name: 'casePagination37',
              component: casePagination37,
            }
            , {
              path: '/casePagination38',
              name: 'casePagination38',
              component: casePagination38,
            }
            , {
              path: '/casePagination39',
              name: 'casePagination39',
              component: casePagination39,
            }
            , {
              path: '/casePagination40',
              name: 'casePagination40',
              component: casePagination40,
            }
            , {
              path: '/casePagination41',
              name: 'casePagination41',
              component: casePagination41,
            }

             , {
              path: '/casePagination42',
              name: 'casePagination42',
              component: casePagination42,
            }
            , {
              path: '/casePagination43',
              name: 'casePagination43',
              component: casePagination43,
            }
            , {
              path: '/casePagination44',
              name: 'casePagination44',
              component: casePagination44,
            }
            , {
              path: '/casePagination45',
              name: 'casePagination45',
              component: casePagination45,
            }
            , {
              path: '/casePagination46',
              name: 'casePagination46',
              component: casePagination46,
            }
            , {
              path: '/casePagination47',
              name: 'casePagination47',
              component: casePagination47,
            }
            , {
              path: '/casePagination48',
              name: 'casePagination48',
              component: casePagination48,
            }
            , {
              path: '/casePagination49',
              name: 'casePagination49',
              component: casePagination49,
            }

            , {
              path: '/casePagination50',
              name: 'casePagination50',
              component: casePagination50,
            }
            , {
              path: '/casePagination51',
              name: 'casePagination51',
              component: casePagination51,
            }
            , {
              path: '/casePagination52',
              name: 'casePagination52',
              component: casePagination52,
            }
            , {
              path: '/casePagination53',
              name: 'casePagination53',
              component: casePagination53,
            }
            , {
              path: '/casePagination54',
              name: 'casePagination54',
              component: casePagination54,
            }
         
            , {
              path: '/casePagination55',
              name: 'casePagination55',
              component: casePagination55,
            }
            , {
              path: '/casePagination56',
              name: 'casePagination56',
              component: casePagination56,
            }
            , {
              path: '/casePagination57',
              name: 'casePagination57',
              component: casePagination57,
            }
            , {
              path: '/casePagination58',
              name: 'casePagination58',
              component: casePagination58,
            }
            , {
              path: '/casePagination59',
              name: 'casePagination59',
              component: casePagination59,
            }
            , {
              path: '/casePagination60',
              name: 'casePagination60',
              component: casePagination60,
            }
            , {
              path: '/casePagination61',
              name: 'casePagination61',
              component: casePagination61,
            }
            , {
              path: '/casePagination62',
              name: 'casePagination62',
              component: casePagination62,
            }
            , {
              path: '/casePagination63',
              name: 'casePagination63',
              component: casePagination63,
            }
            , {
              path: '/casePagination64',
              name: 'casePagination64',
              component: casePagination64,
            }
            , {
              path: '/casePagination66',
              name: 'casePagination66',
              component: casePagination66,
            }
            , {
              path: '/casePagination68',
              name: 'casePagination68',
              component: casePagination68,
            }

          ]
        },
        {
          path: '/soluTion',
          name: 'soluTion',
          component: soluTion,
          children: [{
            path: '/',
            name: 'soTion',
            component: soTion,
          },
          {
            path: '/samCov',
            name: 'samCov',
            component: samCov,
          },
          {
            path: '/samCov1',
            name: 'samCov1',
            component: samCov1,
          },
          {
            path: '/samCov2',
            name: 'samCov2',
            component: samCov2,
          },
          {
            path: '/samCov3',
            name: 'samCov3',
            component: samCov3,
          },
          {
            path: '/samCov4',
            name: 'samCov4',
            component: samCov4,
          },
          {
            path: '/samCov5',
            name: 'samCov5',
            component: samCov5,
          },
          {
            path: '/samCov6',
            name: 'samCov6',
            component: samCov6,
          },
          {
            path: '/samCov7',
            name: 'samCov7',
            component: samCov7,
          },
          {
            path: '/samCov8',
            name: 'samCov8',
            component: samCov8,
          },
          {
            path: '/samCov9',
            name: 'samCov9',
            component: samCov9,
          },
          {
            path: '/samCov10',
            name: 'samCov10',
            component: samCov10,
          },
          {
            path: '/samCov11',
            name: 'samCov11',
            component: samCov11,
          },
          {
            path: '/samCov12',
            name: 'samCov12',
            component: samCov12,
          },
          {
            path: '/samCov13',
            name: 'samCov13',
            component: samCov13,
          },
          {
            path: '/samCov14',
            name: 'samCov14',
            component: samCov14,
          },
          {
            path: '/samCov15',
            name: 'samCov15',
            component: samCov15,
          },
          {
            path: '/samCov16',
            name: 'samCov16',
            component: samCov16,
          },
          {
            path: '/samCov17',
            name: 'samCov17',
            component: samCov17,
          },
          {
            path: '/samCov18',
            name: 'samCov18',
            component: samCov18,
          },
          {
            path: '/samCov19',
            name: 'samCov19',
            component: samCov19,
          },
          {
            path: '/samCov20',
            name: 'samCov20',
            component: samCov20,
          },
          ]

        }
        ,
        {
          path: '/PageHome',
          name: 'PageHome',
          component: PageHome
        },
        {
          path: '/newsCentres',
          name: 'newsCentres',
          component: newsCentres,
          children: [
            {
              path: "/",
              name: 'newsBenes',
              component: newsBenes
            },
            {
              path: '/separateNews1',
              name: ' separateNews1',
              component: separateNews1,
            },
            {
              path: '/separateNews2',
              name: ' separateNews2',
              component: separateNews2,
            },
            {
              path: '/separateNews3',
              name: ' separateNews3',
              component: separateNews3,
            },
            {
              path: '/separateNews4',
              name: ' separateNews4',
              component: separateNews4,
            },
            {
              path: '/separateNews5',
              name: ' separateNews5',
              component: separateNews5,
            },
            {
              path: '/separateNews6',
              name: ' separateNews6',
              component: separateNews6,
            },
            {
              path: '/separateNews7',
              name: ' separateNews7',
              component: separateNews7,
            },
            {
              path: '/separateNews8',
              name: ' separateNews8',
              component: separateNews8,
            },
            {
              path: '/separateNews9',
              name: ' separateNews9',
              component: separateNews9,
            },
            {
              path: '/separateNews10',
              name: ' separateNews10',
              component: separateNews10,
            },
            {
              path: '/separateNews11',
              name: ' separateNews11',
              component: separateNews11,
            },
            {
              path: '/separateNews12',
              name: ' separateNews12',
              component: separateNews12,
            },
            {
              path: '/separateNews13',
              name: ' separateNews13',
              component: separateNews13,
            },
            {
              path: '/separateNews14',
              name: ' separateNews14',
              component: separateNews14,
            },
            {
              path: '/separateNews15',
              name: ' separateNews15',
              component: separateNews15,
            },
            {
              path: '/separateNews16',
              name: ' separateNews16',
              component: separateNews16,
            },
            {
              path: '/separateNews16',
              name: ' separateNews16',
              component: separateNews16,
            },
            {
              path: '/separateNews17',
              name: ' separateNews17',
              component: separateNews17,
            },
            {
              path: '/separateNews18',
              name: ' separateNews18',
              component: separateNews18,
            },
            {
              path: '/separateNews19',
              name: ' separateNews19',
              component: separateNews19,
            },
            {
              path: '/separateNews20',
              name: ' separateNews20',
              component: separateNews20,
            },
            {
              path: '/separateNews21',
              name: ' separateNews21',
              component: separateNews21,
            },
            {
              path: '/separateNews22',
              name: ' separateNews22',
              component: separateNews22,
            },
            {
              path: '/separateNews23',
              name: ' separateNews23',
              component: separateNews23,
            },
            {
              path: '/separateNews24',
              name: ' separateNews24',
              component: separateNews24,
            },
            {
              path: '/separateNews25',
              name: ' separateNews25',
              component: separateNews25,
            }

          ]
        },
        


        {
          path: '/product-center',
          name: 'ProductCenter',
          component: ProductCenter,
          children: [
            {
              path: '/',
              name: 'mmWaveRadarComponent',
              component: mmWaveRadarComponent
            },

            {
              path: '/civilianProducts',
              name: 'civilianProducts',
              component: civilianProducts
            },
            {
              path: '/civilianProducts1',
              name: 'civilianProducts1',
              component: civilianProducts1
            },
            {
              path: '/civilianProducts2',
              name: 'civilianProducts2',
              component: civilianProducts2
            },
            {
              path: '/civilianProducts3',
              name: 'civilianProducts3',
              component: civilianProducts3
            },
            {
              path: '/civilianProducts4',
              name: 'civilianProducts4',
              component: civilianProducts4
            },
            {
              path: '/civilianProducts5',
              name: 'civilianProducts5',
              component: civilianProducts5
            },
            {
              path: '/civilianProducts6',
              name: 'civilianProducts6',
              component: civilianProducts6
            },
            {
              path: '/civilianProducts7',
              name: 'civilianProducts7',
              component: civilianProducts7
            },
            {
              path: '/civilianProducts8',
              name: 'civilianProducts8',
              component: civilianProducts8
            },
            {
              path: '/civilianProducts9',
              name: 'civilianProducts9',
              component: civilianProducts9
            },
            {
              path: '/civilianProducts10',
              name: 'civilianProducts10',
              component: civilianProducts10
            },
            {
              path: '/DoHome',
              name: 'DoHome',
              component: DoHome
            },

            {
              path: '/mationBan1',
              name: 'mationBan1',
              component: mationBan1
            },

            {
              path: '/mationBan2',
              name: 'mationBan2',
              component: mationBan2
            },

            {
              path: '/mationBan3',
              name: 'mationBan3',
              component: mationBan3
            },

            {
              path: '/mationBan4',
              name: 'mationBan4',
              component: mationBan4

            },

            {
              path: '/mationBan5',
              name: 'mationBan5',
              component: mationBan5

            },
            {
              path: '/mationBan6',
              name: 'mationBan6',
              component: mationBan6

            },
            {
              path: '/mationBan7',
              name: 'mationBan7',
              component: mationBan7

            },
            {
              path: '/mationBan8',
              name: 'mationBan8',
              component: mationBan8

            },
            {
              path: '/mationBan9',
              name: 'mationBan9',
              component: mationBan9

            },
            {
              path: '/mationBan10',
              name: 'mationBan10',
              component: mationBan10

            },
            {
              path: '/mationBan11',
              name: 'mationBan11',
              component: mationBan11

            },
            {
              path: '/mationBan12',
              name: 'mationBan12',
              component: mationBan12

            },
            {
              path: '/mationBan13',
              name: 'mationBan13',
              component: mationBan13

            },
            {
              path: '/mationBan14',
              name: 'mationBan14',
              component: mationBan14

            },
            {
              path: '/mationBan15',
              name: 'mationBan15',
              component: mationBan15
            },
            {
              path: '/mationBan16',
              name: 'mationBan16',
              component: mationBan16

            },
            {
              path: '/ledDisplay1',
              name: 'ledDisplay1',
              component: ledDisplay1

            },
            {
              path: '/ledDisplay2',
              name: 'ledDisplay2',
              component: ledDisplay2

            },
            {
              path: '/ledDisplay3',
              name: 'ledDisplay3',
              component: ledDisplay3

            },
            {
              path: '/ledDisplay4',
              name: 'ledDisplay4',
              component: ledDisplay4

            },
            {
              path: '/ledDisplay5',
              name: 'ledDisplay5',
              component: ledDisplay5

            },
            {
              path: '/ledDisplay6',
              name: 'ledDisplay6',
              component: ledDisplay6

            },
            {
              path: '/ledDisplay7',
              name: 'ledDisplay7',
              component: ledDisplay7

            },
            {
              path: '/ledDisplay8',
              name: 'ledDisplay8',
              component: ledDisplay8

            },
            {
              path: '/ledDisplay9',
              name: 'ledDisplay9',
              component: ledDisplay9

            },
            {
              path: '/ledDisplay10',
              name: 'ledDisplay10',
              component: ledDisplay10

            },
            {
              path: '/ledDisplay11',
              name: 'ledDisplay11',
              component: ledDisplay11

            },
            {
              path: '/ledDisplay12',
              name: 'ledDisplay12',
              component: ledDisplay12

            },
            {
              path: '/ledLighting1',
              name: 'ledLighting1',
              component: ledLighting1

            },
            {
              path: '/ledLighting2',
              name: 'ledLighting2',
              component: ledLighting2

            },
            {
              path: '/ledLighting3',
              name: 'ledLighting3',
              component: ledLighting3

            },
            {
              path: '/ledLighting4',
              name: 'ledLighting4',
              component: ledLighting4

            },
            {
              path: '/ledLighting5',
              name: 'ledLighting5',
              component: ledLighting5

            },
            {
              path: '/millimeterWaveRadar1',
              name: 'millimeterWaveRadar1',
              component: millimeterWaveRadar1

            },
            {
              path: '/millimeterWaveRadar2',
              name: 'millimeterWaveRadar2',
              component: millimeterWaveRadar2

            },
            {
              path: '/millimeterWaveRadar3',
              name: 'millimeterWaveRadar3',
              component: millimeterWaveRadar3

            },
            {
              path: '/millimeterWaveRadar4',
              name: 'millimeterWaveRadar4',
              component: millimeterWaveRadar4

            },
            {
              path: '/millimeterWaveRadar5',
              name: 'millimeterWaveRadar5',
              component: millimeterWaveRadar5

            },
            {
              path: '/millimeterWaveRadar6',
              name: 'millimeterWaveRadar6',
              component: millimeterWaveRadar6

            },
            {
              path: '/millimeterWaveRadar7',
              name: 'millimeterWaveRadar7',
              component: millimeterWaveRadar7

            },
            {
              path: '/millimeterWaveRadar8',
              name: 'millimeterWaveRadar8',
              component: millimeterWaveRadar8

            },
            {
              path: '/millimeterWaveRadar9',
              name: 'millimeterWaveRadar9',
              component: millimeterWaveRadar9

            },
            {
              path: '/inTrans1',
              name: ' inTrans1',
              component: inTrans1

            },
            {
              path: '/inTrans2',
              name: ' inTrans2',
              component: inTrans2

            },
            {
              path: '/inTrans3',
              name: ' inTrans3',
              component: inTrans3

            },
            {
              path: '/inTrans4',
              name: ' inTrans4',
              component: inTrans4

            },
            {
              path: '/inTrans5',
              name: ' inTrans5',
              component: inTrans5

            },
            {
              path: '/inTrans6',
              name: ' inTrans6',
              component: inTrans6

            },
            {
              path: '/inTrans7',
              name: ' inTrans7',
              component: inTrans7

            },
            {
              path: '/inTrans8',
              name: ' inTrans8',
              component: inTrans8

            },
            {
              path: '/inTrans9',
              name: ' inTrans9',
              component: inTrans9

            },
          ]
        },
        {
          path: '/aboutUs',
          name: 'aboutUs',
          component: aboutUs,
          children: [

            {
              path: '/',
              name: 'viewsCenter',
              component: viewsCenter,
            },


          ]

        }
        ,
        {
          path: '/serviceSupport',
          name: 'serviceSupport',
          component: serviceSupport,

        }
        ,

        {
          path: '/joinUs',
          name: 'joinUs',
          component: joinUs,
          children: [
            {
              path: '/',
              name: 'justGo',
              component: justGo,
            },
            {
              path: '/justGo',
              name: 'justGo',
              component: justGo,
            },
            {
              path: '/joinGo',
              name: 'joinGo',
              component: joinGo,
            },

          ]
        }
      ]
    }
  ]
})



