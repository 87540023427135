import Vue from 'vue'
import App from './App.vue'
import TDesign from 'tdesign-vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
import 'social-share.js/dist/css/share.min.css'; 
import '../public/rem.js'; // 如果public文件夹是项目根目录下的子目录


// import '../public/flexible.js'; 







router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // 将页面滚动到顶部
 
  next();
  });





Vue.config.productionTip = false
Vue.use(TDesign);
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  ak: 'GyVlTguDheARKGdCDCsQVSdc'  //这个地方是官方提供的ak密钥
})








new Vue({
  router, // 将router实例注入到Vue实例中
  render: h => h(App),
}).$mount('#app')