<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center', query: { tab: 'third' }}"> 可变情报板系列
                </el-breadcrumb-item>
                <el-breadcrumb-item>室内信息显示屏</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="display: flex;justify-content: center;margin-top: .4167rem;">

            <div>
                <img src="../../assets/026.webp" alt="" style="height: 1.1302rem;">
            </div>
            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">

                <p class="name">室内信息显示屏</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">
                    室内信息显示屏采用前端视频采集，点对点轻松拼接成等标准分辨率大屏，满足商业、教育、交通等场所需求。具备完全前维护、模组分离式设计，拼接精度≤0.1mm，双备份电源信号系统，IP65防护等级，超薄安装，无线连接、热插拔技术，LED专业拼接墙，高刷新率和低亮高灰特性。
                </P>
                <div style="margin-top: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"> </icon>
                        技术热线
                    </div>
                    <div class="tel"> 181&nbsp;&nbsp;2248&nbsp;&nbsp;4161 </div>
                </div>
            </div>
        </div>
        <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ; margin: 0 auto;margin-top: .1042rem; ">
            详细参数
        </div>
        <!--  style="width: 6.5104rem;margin: 0 auto;"-->
        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt"><em>像素间距</em></div>
                    <div class="msg"> 1.0mm/1.2mm/1.3mm/1.4mm/1.5mm/1.6mm/1.7mm/1.9mm/2.0mm/2.2mm/2.5mm/2.9mm </div>
                </li>
                <li>
                    <div class="txt"><em> led型号 </em></div>
                    <div class="msg"> SMD0606、SMD0808、SMD1010、SMD1515、SMD2121 </div>
                </li>
                <li>
                    <div class="txt"><em>灰度等级(Bit)</em></div>
                    <div class="msg">12-24Bit</div>
                </li>
                <li>
                    <div class="txt"><em>色温</em></div>
                    <div class="msg">2000K~9500K</div>
                </li> 

                <li>
                    <div class="txt"><em> 刷新率 </em></div>
                    <div class="msg"> 普刷:＜1920Hz，高刷新:≥3840Hz </div>
                </li>                   a

                <li>
                    <div class="txt"><em>亮度调节</em></div>
                    <div class="msg">32/64 级亮度控制，手动/自动/定时，可远程控制 </div>
                </li>

                <li>

                    <div class="txt"><em>可视距离</em></div>
                    <div class="msg">≥30m</div>
                </li>

                <li>
                    <div class="txt"><em>平均功耗</em></div>
                    <div class="msg">≤500W/m²</div>
                </li>

                <li>
                    <div class="txt"><em> LED平均寿命</em></div>
                    <div class="msg">≥10000小时</div>
                </li>

                <li>
                    <div class="txt"><em> MTBF MTTR</em></div>
                    <div class="msg">≥30000小时，≤0.5小时</div>
                </li>

            </ul>
        </div>
        <div style="display: flex;justify-content: center; margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack"> 返回列表 </el-button>
        </div>

    </div>
</template> 

<script>
    
    export default {
        methods: {
            goBack() {
                this.$router.push({ path: '/product-center', query: { tab: 'third' } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' }});
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }

</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>