<template>
    <div>
      <div style="width: 6.5104rem;margin: 0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center', query: { tab: 'six' }}">雷达通信模组</el-breadcrumb-item>
          <el-breadcrumb-item>雷达感应模块 10.525GHz系列</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  
      <div style="display: flex;justify-content: center;margin-top: .4167rem;">
        <div  style="display: inline-block;transform: scale(1.05) translateX(-0.6771rem);">
          <img src="../../assets/m105.webp" alt="" style="">
        </div>
  
        <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
          <p class="name">雷达感应模块 10.525GHz系列</p>
          <P class="content" style="text-indent: .1198rem;font-size: .0833rem">10.525GHz微波雷达感应模块由丰海科技自主研发，基于多普勒雷达原理，能够实现运动靠近感应功能，并具备自动控制和自动调节感应距离的能力。该模块尺寸为20mm x 22mm，最远探测距离可达10米。在应用于LED灯时，模块能够在感应到人体存在后自动开灯，人体离开后自动关灯，实现安全节电，同时不受声音、物体等外界因素的干扰。
          </P>
          <div style="margin-top: .2604rem;">
            <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
              <icon class="el-icon-phone-outline"></icon>技术热线
            </div>
            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
  
          </div>
  
  
        </div>
  
  
  
      </div>
  
      <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
        关键参数</div>
  
      <!--  style="width: 6.5104rem;margin: 0 auto;"-->
      <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
        <ul>
          
          <!-- ≥   ≤ -->
          <li>
            <div class="txt"><em>微波发射频率</em></div>
            <div class="msg">10.5GHz-10.55GHz</div>
          </li>

          <li>
            <div class="txt"><em>微波发射功率</em></div>
            <div class="msg">0dBm</div>
          </li>

        
          <li>
            <div class="txt"><em>输入电压</em></div>
            <div class="msg">3.3-16v</div>
          </li>

          <li>
            <div class="txt"><em>输出高电平</em></div>
            <div class="msg">3.3v</div>
          </li>

          <li>
            <div class="txt"><em>输出低电平</em></div>
            <div class="msg">0</div>
          </li>
          <li>
            <div class="txt"><em>工作电流</em></div>
            <div class="msg">3mA-10mA</div>
          </li>
          <li>
            <div class="txt"><em>感应距离（顶装）</em></div>
            <div class="msg">2-4m</div>
          </li>
          <li>
            <div class="txt"><em>感应距离（侧装）</em></div>
            <div class="msg">6-10m</div>
          </li>

          <li>
            <div class="txt"><em>垂直探测角度</em></div>
            <div class="msg">360°</div>
          </li>

          <li>
            <div class="txt"><em>水平探测角度</em></div>
            <div class="msg">120°</div>
          </li>

          <li>
            <div class="txt"><em>光敏阈值</em></div>
            <div class="msg">10Lux</div>
          </li>

          <li>
            <div class="txt"><em>工作温度</em></div>
            <div class="msg">-20°C-70°C</div>
          </li>
          <li>
            <div class="txt"><em>存储温度</em></div>
            <div class="msg">-40°C-85°C</div>
          </li>
       
        </ul>
      </div>
  
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
  
    </div>
  
  
  
  
  </template>
  <script>
  
  
    export default {
      methods: {
        goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'six' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
        ,
  
        //       goTo(){
        //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
        //     const windowHeight = window.innerHeight; // 
        //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
        //     window.scrollTo(0, scrollTo); //
        //   },
      }
    }
  
  
  
  
  </script>
  
  <style scoped>
    .name {
      padding-bottom: .1042rem;
      border-bottom: #eeeeee solid .0052rem;
      font-size: .1042rem;
      color: #2056c0;
      font-weight: bold;
      position: relative;
    }
  
    .msgList {
      padding-top: .2604rem;
  
      border-bottom: #eee solid .0052rem;
    }
  
    .msgList li {
      border: #c6c6c6 solid .0052rem;
      padding: .0521rem .1042rem .0521rem 1.0938rem;
      position: relative;
      font-size: .0729rem;
      margin-bottom: .0521rem;
      text-align: center;
      line-height: .2604rem;
    }
  
    li {
      list-style-position: outside;
      list-style-type: none;
  
    }
  
    .msgList li .msg {
      line-height: .1667rem;
      color: #999;
    }
  
    .content {
      padding-top: .1563rem;
      font-size: .0729rem;
      line-height: .125rem;
      color: #434343;
  
    }
  
    .tel {
      padding-top: .0521rem;
      font-size: .1875rem;
      color: #bb3944;
      font-family: Impact;
    }
  
    .txt {
      position: absolute;
      left: 0rem;
      top: 0rem;
      width: .9896rem;
      height: 100%;
      text-align: center;
      color: #FFFFFF;
      background: #2056c0;
      font-size: .0729rem;
      font-family: Arial, "微软雅黑";
  
    }
  
    .tittle {
  
      margin-top: .5208rem;
      height: .2083rem;
      text-align: center;
      line-height: .2083rem;
      font-weight: bold;
      font-size: .0833rem;
      color: #fff;
  
      position: relative;
    }
  </style>