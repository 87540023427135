<template>
  <div class="contain"> 

  
   
    <div :style="{ width: '100%', height: '3.3333rem', backgroundImage: 'url(' + require('../assets/服务支持12345.webp') + ')', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }">
        </div>
        <!-- 轮播图部分 -->

        <div
          style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
          <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/技术支持.png" alt="">
          <p
            style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
            技术支持</p>
          <p
            style=" display: inline-block; height: .0104rem; width: .3333rem; background-color: #407BF5; font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
          </p>

        </div>

        <div style="height:auto;display: flex; justify-content: center;align-items: center;">
          <div class="tab-container" style="padding-bottom: .1563rem;" >
            <el-tabs stretch="true" v-model="activeName" @tab-click="handleClick"  style="margin-top: .2604rem; width:6.25rem; height: auto;font-size: .1042rem;" >
              <el-tab-pane label="下载中心" name="first">
                <el-table :data="tableData" border style="width: 100%">
                  <el-table-column fixed prop="date" label="上传日期"> </el-table-column>
                  <el-table-column prop="name" label="系统名" > </el-table-column>
                  <el-table-column prop="address" label="系统概述"> </el-table-column>
                  <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                      <a :href="scope.row.pdfUrl" target="_blank" style="color: #407BF5;text-decoration: none;"> 查看 </a> 
                      <el-button @click="downloadFile(scope.row)" type="text" size="normal" style="margin-left: .1563rem; color: #407BF5;"> 
                        链接 
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="常见问题" name="second">
                <el-collapse v-model="activeNames" @change="handleChange">
                  <el-collapse-item title="问：整块单元板不亮（黑屏）" name="1">

                    <div style="font-size: .0833rem;"> A：连续几块单元板横方向不亮，检查正常单元板与异常单元板之间的排线连接是否正常。及第一块单元板5V供电是否正常
                    </div>
                    <div style="font-size: .0833rem;"> B：连续几块单元板纵方向不亮，检查此列电源供电是否正常 </div>
                  </el-collapse-item>
                  <el-collapse-item title="问：显示文件不全或位置不对" name="2">

                    <div style="font-size: .0833rem;"> A：首先检查软件中情报板参数是否和安装工程师给的情报板参数一致，如果不对，再进行调节</div>

                    <div style="font-size: .0833rem;"> B：如显示不全面，检查控制卡的文件大小是否与屏体的“屏体大小”一致 </div>

                    <div style="font-size: .0833rem;"> C：打开情报板箱体门检查一个主板或转接卡是否落上导电体 </div>

                  </el-collapse-item>

                  <el-collapse-item title="问：整屏不亮（黑屏）" name="3">
                    <div style="font-size: .0833rem;"> A：检查屏体的供电情况，用试电笔或万用表检测开关连接用电器端是否有电，开关可能出现问题，或者短路。</div>
                    <div style="font-size: .0833rem;"> B：检查控制主板是否供电，50P排线是否松动。</div>
                    <div style="font-size: .0833rem;"> C：检查控制主板内容是否通成黑色内容。</div>
                  </el-collapse-item>


                  <el-collapse-item title="问：IF-KXB系列可变信息板总电源开关跳闸" name="4">
                    <div style="font-size: .0833rem;"> A：检查机房至情报板电源线路是否短路、断路、漏电，火线、零线、地线顺序是否正确 </div>
                    <div style="font-size: .0833rem;"> B：检查开关电源是否短路、烧毁 </div>
                    <div style="font-size: .0833rem;"> C：检查屏体内部供电线路有无短路、外皮破损 </div>
                    <div style="font-size: .0833rem;"> D：检查总电源开关。注：第4项数据仅供参考，其实际数据与点亮的LED数量和模块数目有关 </div>
                    <div style="font-size: .0833rem;"> E：检査输入电流: 32x32x10 门架,一般在 5.5A 左右; 48x96 隧道内 , 在 3. 3A左右;48x48 立柱，在2. 7A左右 </div>
                  </el-collapse-item>


                  <el-collapse-item title="问：信息板监控电脑无法读取、下载显示屏数据" name="5">
                    <div style="font-size: .0833rem;"> A：检查控制卡上电源指示灯(如下图红灯所示)显示情况。如果亮了则已正常上电，如不亮则可能是控制卡未上电</div>
                  </el-collapse-item>


                  <el-collapse-item title="问：信息板全屏有显示，显示字乱故障" name="6" style="font-size: .2604rem;">
                    <div style="font-size: .0833rem;"> A：检查模组参数是否配置错误          </div>
                    <div style="font-size: .0833rem;"> B：检查字库文件是否丢失损坏，重新烧写字库 </div>
                    <div style="font-size: .0833rem;"> C：检查转接卡是否用错或损坏 </div>
                  </el-collapse-item>
                  
                </el-collapse>

              </el-tab-pane>
              <el-tab-pane label="技术热线" name="third">
  
                <div style="width: 100%;display: flex;justify-content:center ;align-items: center; padding-bottom: .1563rem;padding-top: .1042rem;">
                 <div style="width:3.125rem;height:2.0833rem;">
                  <img src="../assets/电路制作.jpg" alt="" style="width:100%; height: 100%;">              
                </div>

                  <div style="margin-left:.1042rem;">

                    <p>技术热线</p>
                    <p style="padding-top: .0521rem;
                    font-size: .1875rem;
                    color: #bb3944;
                    font-family: Impact;">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161
                    </p>

                  </div>

                </div>
                
              </el-tab-pane>
              
            </el-tabs>
          </div>
    
        </div>


      

    
  </div>
  </template>
  
  <script>
    export default {
      name: 'serviceSupport',
    data() {
      return {
        activeName: 'first',
        activeNames: ['1'],
        tableData: [ 
           { date: '2023-08', name: '视频监控AI智能监测系统', address: '基于视频监控大数据的智能安防系统，旨在检测和预防社会生活中的暴力行为，保障公共安全', fileUrl: 'http://8.134.80.97:8084/login',pdfUrl:'/pptx/shipin.pdf' },
           { date: '2023-06', name: '智慧交警AI调度系统', address: ' 利用大数据技术实现智能调度和优化排班的系统，为各行业提供高效的人员安排和资源管理解决方案', fileUrl: 'http://8.134.80.97:8082/login',pdfUrl:'/pptx/paiban.pdf' },
           { date: '2022-05', name: '雾天行车诱导系统', address: '专为雾天行车安全而设计的智能导航系统，利用先进的传感技术和实时数据分析，提供准确的导航和警示信息，帮助驾驶员安全驾驶在雾天恶劣环境下', fileUrl: 'http://8.134.80.97:8080/',pdfUrl:'/pptx/wutian.pdf' },
          ]
      };
    },
    mounted() {
  this.handleTabChange(this.$route.query.tab);
},
watch: {
  '$route.query.tab': {
    immediate: true,
    handler(newTab) {
      this.handleTabChange(newTab);
    }
  }
},
    // mounted(){
    //   const tab = this.$route.query.tab; 
    //   if (tab === 'first') {
    //   this.activeName = 'first'; // 设置第一个选项卡为激活状态
    // } else if (tab === 'second') {
    //   this.activeName = 'second'; // 设置第二个选项卡为激活状态
    // } else if (tab === 'third') {
    //   this.activeName = 'third'; // 设置第三个选项卡为激活状态
    // }
    // },
    methods: {
      handleTabChange(tab) {
    if (tab === 'first') {
      this.activeName = 'first'; // 设置第一个选项卡为激活状态
    } else if (tab === 'second') {
      this.activeName = 'second'; // 设置第二个选项卡为激活状态
    }else if (tab === 'third') {
      this.activeName = 'third'; // 设置第二个选项卡为激活状态
    }
    console.log(tab); // 打印传递过来的 tab 参数
  },

      downloadFile(row) {
      const link = document.createElement('a');
      link.href = row.fileUrl;
      link.download = row.name;
      link.target = '_blank';
      link.click();
    },
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }

  };
  </script>
  
  <style scoped>
  h1 {
    color: blue;
  }
  .el-tabs__item {
  width: 300px; 
}

.el-tabs.el-tabs--top{
  width: 100%
}
#tab-first .el-tabs__item.is-top{
  width: 1.5625rem;
}
.contain /deep/ .el-collapse-item__header{
  font-size: .0938rem;
} 
</style>