<template>
    <div class="contain">
        <div style="width:100%;display: flex;justify-content: center;background-color: #F3F3F3;">



            <div style="width:7.1771rem;font-size: .0938rem;" class="opp">


                <div style="width: 7.2865rem;font-size: .1042rem; text-align: left;top: .4167rem;z-index: 99;transform: translateY(-0.1302rem);" >

                    <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: .0833rem;">
                        <el-breadcrumb-item :to="{ path: '/product-center' }" > 常规产品 </el-breadcrumb-item>
                        <el-breadcrumb-item
                            :to="{ path: '/civilianProducts'}"> 民用产品 </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>



                <t-space style="width: 100%;margin-top: .0521rem;" direction="vertical">
                    <t-tabs theme="card" v-model="activeName" type="border-card" @change="changeTab">
                        <t-tab-panel style="height:auto;margin-bottom: .0938rem;" value="first" label="传感器系列">

                            <div style="padding: .1667rem .5rem;position: relative;">
                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                    雷达传感器
                                </p>    

                                    <div style="width:100%; display: flex; flex-wrap: wrap; margin:.0833rem 0; ">
                                      <div class="div1" @click="redirectToRadar5" @mouseover="zoomInImage"
                                            @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">

                                            <img src="../assets/m222.webp" style="height:.9792rem;">

                                            <p class="wahha">
                                                5.8GHz系列
                                            </p>

                                    </div>
    
                                        <div class="div2" @click="redirectToRadar6" @mouseover="zoomInImage"
                                            @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">

                                            <img src="../assets/m105.webp" style="height: 1.1979rem;">

                                            <p class="wahha">
                                                10.525GHz系列
                                            </p>

                                        </div>
    
                                        <div class="div3" @click="redirectToRadar7" @mouseover="zoomInImage"
                                            @mouseout="zoomOutImage"
                                            style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                            <img src="../assets/m240.webp" style="height: 1.1979rem;">

                                            <p class="wahha">
                                                24GHz系列
                                            </p>
                                        </div>

                                        <div class="div4" @click="redirectToRadar69" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem; position:relative; margin-left: .125rem; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                       
                                        <img src="../assets/P24.png" style="height: .9792rem;">

                                        <p class="wahha">
                                            60GHz系列
                                        </p>
                                </div>

                                    </div>

                            </div>
                        </t-tab-panel>

                        <t-tab-panel value="second" style="height:auto;margin-bottom: .0938rem;" label="通信模组系列">
                            <div style="padding: .1667rem .5rem;position: relative;">
                                <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                    <!-- 雷达感应模块 丨 IF-LIGHT-RADAR -->
                                    通信模组
                                </p>

                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <!-- <div class="div1" @click="redirectToRadar4('page1')" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/tx1.png" style="height: .9792rem;">
                                        <p class="wahha">
                                            433MHz通信模组
                                        </p>
                                </div> -->

                                    <div class="div1" @click="redirectToRadar4('page2')" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;  display: flex;justify-content: center;align-items: center;">

                                        <img src="../assets/tx2.png" style="height:.9792rem;">

                                        <p class="wahha">
                                            433/868/915MHz通信模组
                                        </p>

                                </div>

                                

                                <div class="div2" @click="redirectToRadar4('page3')" @mouseover="zoomInImage"
                                @mouseout="zoomOutImage"
                                style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;;display: flex;justify-content: center;align-items: center;">
                                <img src="../assets/tx5.png" style="height: .9792rem;">
                                <p class="wahha">
                                    4G LTE通信模组
                                </p>
                            </div>


                            <div class="div3" @click="redirectToRadar4('page4')" @mouseover="zoomInImage"
                                @mouseout="zoomOutImage"
                                style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                               

                                <img src="../assets/tx6.png" style="height:.9792rem;">
                                <p class="wahha">
                                    LoRa透传模组
                                </p>

                        </div>

                              
                        <div class="div4" @click="redirectToRadar4('page5')" @mouseover="zoomInImage"
                        @mouseout="zoomOutImage"
                        style="width:1.375rem;position:relative; height: 1.375rem;margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                        <img src="../assets/tx7.png" style="height: 1.1979rem;">

                        <p class="wahha">
                            蓝牙模组
                        </p>

                    </div>
                         

                                    
                                </div>

                                                              
                                <!-- <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;"> -->
                                    
                                    <!-- <div class="div1" @click="redirectToRadar8" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/008.webp">
                                        <p class="wahha">
                                            激光雷达</p>
                                    </div> -->


                           
                                <!-- </div> -->


                       
                            </div>

                            <!-- <t-pagination style="position: absolute;left: -40%;" :total="30" :totalContent="false"
                                    showPreviousAndNextBtn :showPageSize="false" showPageNumber /> -->

                        </t-tab-panel>

                        <!-- 

                         -->

                        <t-tab-panel value="third" style="height:auto;margin-bottom: .0938rem;" label="控制类系列">

                            <div style="padding: .1667rem .5rem;position: relative;">
                                <!-- <p
                                    style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                    
                                    线控器系列
                                </p> -->

                                <!-- <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <div class="div1" @click="redirectToRadar68('page1')" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/kz1.png" style="height: .9792rem;">
                                        <p class="wahha">
                                            IF-KJR-120L
                                        </p>

                                </div>

                                    <div class="div2" @click="redirectToRadar68('page2')" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem; display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/kz2.png" style="height:.9792rem;">
                                        <p class="wahha">
                                            IF-KJR-120N/M(标准版)
                                        </p>
                                 </div>

                  

                                    <div class="div3" @click="redirectToRadar68('page3')" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem; margin-left: .125rem;display: flex;justify-content: center;align-items: center;">
                                        
                                        <img src="../assets/kz4.png" style="height: 1.1979rem;">
                                        <p class="wahha">
                                           IF-XKQ-120X
                                        </p>

                                    </div>

                   

                                    
                                </div> -->
                                <p
                                style="font-size: .0938rem;display: flex; flex-wrap: wrap; font-weight: bold; color: #407BF5; margin: 0;">
                                无刷电机控制
                            </p>

                                <div style="width:100%; display: flex; flex-wrap: wrap;margin:.0833rem 0;">
                                    <!-- <div class="div1" @click="redirectToRadar8" @mouseover="zoomInImage"
                                        @mouseout="zoomOutImage"
                                        style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                        <img src="../assets/008.webp">
                                        <p class="wahha">
                                            激光雷达</p>
                                    </div> -->

                                    <div class="div1" @click="redirectToRadar68('page4')" @mouseover="zoomInImage"
                                    @mouseout="zoomOutImage"
                                    style="width:1.375rem;position:relative; height: 1.375rem;display: flex;justify-content: center;align-items: center;">
                                    <img src="../assets/kz5.png" style=" height: .9792rem; ">
                                    <p class="wahha">
                                        无刷电机控制
                                    </p>
                            </div>
                    
                                </div>
                            </div>

                            <!-- <t-pagination style="position: absolute;left: -40%;" :total="30" :totalContent="false"
                                    showPreviousAndNextBtn :showPageSize="false" showPageNumber />
                             -->
                        </t-tab-panel>

                    </t-tabs>
                </t-space>

            </div>
        </div>
    </div>


</template>

<script>
    export default {

        data() {
            return {

                isShown: false,
                productCenterImage: require('../assets/pood1.webp'),
                activeName: 'first',
            };
        },
        //         mounted() {
        //   this.handleTabChange(this.$route.query.tab);
        // },
        // watch: {

        //   '$route.query.tab': {
            
        //     immediate: true,

        //     handler(newTab) {

        //       this.handleTabChange(newTab);

        //     }
        //   }
        // },

        // mounted() {
        //     const tab = this.$route.query.tab;
        //     if (tab === 'first') {
        //         this.value2 = 'first'; // 设置第一个选项卡为激活状态
        //     } else if (tab === 'second') {
        //         this.value2 = 'second'; // 设置第二个选项卡为激活状态
        //     } else if (tab === 'third') {
        //         this.value2 = 'third'; // 设置第三个选项卡为激活状态
        //     } else if (tab === 'four') {
        //         this.value2 = 'four';
        //     } else if (tab === 'Five') {
        //         this.value2 = 'Five';
        //     } else if (tab === 'six') {
        //         this.value2 = 'six';
        //     }
        //     else if (tab === 'seven') {
        //         this.value2 = 'seven';
        //     }else if (tab === 'eight') {
        //         this.value2 = 'eight';
        //     }else if (tab === 'nine') {
        //         this.value2 = 'nine';
        //     }
        // },
        mounted() {
            this.handleTabChange(this.$route.query.tab);
        },
        watch: {
            '$route.query.tab': {
                immediate: true,
                handler(newTab) {
                    this.handleTabChange(newTab);
                }
            }
        },
        
        methods: {
            handleTabChange(tab) {
                if (tab === 'first') {
                    this.activeName = 'first'; // 设置第一个选项卡为激活状态
                } else if (tab === 'second') {
                    this.activeName = 'second'; // 设置第二个选项卡为激活状态
                }
                 else if (tab === 'third') {
                    this.activeName = 'third'; // 设置第三个选项卡为激活状态
                }

                else if (tab === 'four') {
                    this.activeName = 'four'; // 设置第三个选项卡为激活状态
                }

                else if (tab === 'Five') {
                    this.activeName = 'Five'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'six') {
                    this.activeName = 'six'; // 设置第三个选项卡为激活状态
                } else if (tab === 'seven') {
                    this.activeName = 'seven'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'eight') {
                    this.activeName = 'eight'; // 设置第三个选项卡为激活状态
                }
                else if (tab === 'nine') {
                    this.activeName = 'nine'; // 设置第三个选项卡为激活状态
                }

                console.log(tab); // 打印传递过来的 tab 参数
            },

            zoomInImage(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.3s";
                img.style.transform = 'scale(1.2)';
                // const divp = event.currentTarget.querySelector('.divp');
                // divp.style.display = 'block';
                const pl = event.currentTarget.querySelector('p');
                pl.style.transition = "0.2s";  
                pl.style.fontSize = .0938 + "rem";
                pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)";
                // const pl = event.currentTarget.querySelector('p');
                // pl.style.transition = "0.2s";
                // pl.style.fontSize = 18 + "px"
                // pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)";

            },
            //另外当前元素的divp
            zoomOutImage(event) {

                const img = event.currentTarget.querySelector('img');
                img.style.transform = 'scale(1)';
                // const divp = event.currentTarget.querySelector('.divp');
                // divp.style.display = 'none';
                const pl = event.currentTarget.querySelector('p');
                pl.style.opacity = "1";
                pl.style.fontSize = .0833 + "rem";
                pl.style.transition = "0.4s";
                pl.style.background = "linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%)"

            },
            redirectToRadar1() {
                this.$router.push('/millimeterWaveRadar1');
                // this.$router.push('/inTrans1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar2() {
                this.$router.push('/millimeterWaveRadar2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar3() {
                this.$router.push('/millimeterWaveRadar3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToRadar4(tabName) {
                this.$router.push({ path: "/civilianProducts4", query: { tab: tabName } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToRadar68(tabName) {
                this.$router.push({ path: "/civilianProducts5", query: { tab: tabName } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToRadar69() {
                this.$router.push('/civilianProducts8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },


            redirectToRadar5() {
                this.$router.push('/civilianProducts1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToRadar6() {
                this.$router.push('/civilianProducts2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToRadar7() {
                this.$router.push('/civilianProducts3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToRadar8() {
                this.$router.push('/millimeterWaveRadar8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToRadar9() {
                this.$router.push('/millimeterWaveRadar9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome1() {
                this.$router.push('/mationBan1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome2() {
                this.$router.push('/mationBan2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome3() {
                this.$router.push('/mationBan3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome4() {
                this.$router.push('/mationBan4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome5() {
                this.$router.push('/mationBan5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome6() {
                this.$router.push('/mationBan6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome7() {
                this.$router.push('/mationBan7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome8() {
                this.$router.push('/mationBan8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome9() {
                this.$router.push('/mationBan9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome10() {
                this.$router.push('/mationBan10');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome11() {
                this.$router.push('/mationBan11');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome12() {
                this.$router.push('/mationBan12');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome13() {
                this.$router.push('/mationBan13');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome14() {
                this.$router.push('/mationBan14');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome15() {
                this.$router.push('/mationBan15');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToHome16() {
                this.$router.push('/mationBan16');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            
            redirectToDis1() {
                this.$router.push('/ledDisplay1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToDis2() {
                this.$router.push('/ledDisplay2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis3() {
                this.$router.push('/ledDisplay3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis4() {
                this.$router.push('/ledDisplay4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis5() {
                this.$router.push('/ledDisplay5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis6() {
                this.$router.push('/ledDisplay6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis7() {
                this.$router.push('/ledDisplay7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis8() {
                this.$router.push('/ledDisplay8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis9() {
                this.$router.push('/ledDisplay9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis10() {
                this.$router.push('/ledDisplay10');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis11() {
                this.$router.push('/ledDisplay11');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            redirectToDis12() {
                this.$router.push('/ledDisplay12');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight1() {
                this.$router.push('/ledLighting1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight2() {
                this.$router.push('/ledLighting2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight3() {
                this.$router.push('/ledLighting3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight4() {
                this.$router.push('/ledLighting4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToLight5() {
                this.$router.push('/ledLighting5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic1() {
                this.$router.push('/inTrans1');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic2() {
                this.$router.push('/inTrans2');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic3() {
                this.$router.push('/inTrans3');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置//这里要改哦
            },
            redirectToTraffic4() {
                this.$router.push('/inTrans4');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic5() {
                this.$router.push('/inTrans5');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic6() {
                this.$router.push('/inTrans6');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic7() {
                this.$router.push('/inTrans7');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic8() {
                this.$router.push('/inTrans8');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            redirectToTraffic9() {
                this.$router.push('/inTrans9');
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            changeTab(newValue) {
                this.value2 = newValue;
                this.$emit('tabChanged', newValue);
                if (newValue === 'first') {
                    this.productCenterImage = require('../assets/pood1.webp');
                } else if (newValue === 'second') {
                    this.productCenterImage = require('../assets/pod1.png');
                } else if (newValue === 'third') {
                    this.productCenterImage = require('../assets/pood2.png');
                } else if (newValue === 'four') {
                    this.productCenterImage = require('../assets/pood3.png');
                } else if (newValue === 'Five') {
                    this.productCenterImage = require('../assets/pood5.png');
                } else if (newValue === 'six') {
                    this.productCenterImage = require('../assets/pood5.webp');
                } else if (newValue === 'seven') {
                    this.productCenterImage = require('../assets/pood5.png');
                }
                // 添加您已有的change事件的逻辑代码
            },
        }
    }

</script>

<style scoped>
    .wahaha {
        overflow: hidden;
    }

    .whhimg {
        width: 1.25rem;
    }

    .wahha {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, #37405E 100%);
        opacity: 0.8;
        width: 100%;
        position: absolute;
        bottom: 0;
        font-size: .0938rem;
        color: #FFFFFF;
        line-height: .2083rem;
        font-weight: 400;
        padding-left: .0521rem;
        box-sizing: border-box;
        word-wrap: break-word;
        text-align: center;
    }

    .contain /deep/ .t-tabs {
        border: none;
        box-shadow: none;
    }





    .contain /deep/ .t-tabs__nav-scroll {}



    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item {
        padding-right: .1563rem;
        padding-left: .1563rem;




    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item:not(:first-of-type) {

        border-left: none;
        border-right: none;

    }

    .contain /deep/ .t-tabs__nav-scroll {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active {

        border-right-color: #2056c0;


    }




    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active {
        border-bottom-color: #2056c0;

    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item:not(:first-of-type) {
        border-left: none;
    }

    .contain /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active {
        border-right-color: #2056c0;
    }

    .contain /deep/ .t-tabs__nav-item.t-size-m {
        font-size: .0938rem;
    }



    /* .page2 {
        background-color: #5B5B5B;
    } */


    h1 {
        color: blue;
    }

    /* .opp /deep/ .t-tabs__nav--card.t-tabs__nav-item.t-is-active{
        border-right-color: #407BF5;
    }
    .opp /deep/ .t-space-vertical {
        border: none;
        box-shadow: none;
    }

    .opp /deep/ .el-tabs__nav-scroll {
        background-color: #E9E9E9;

    } */



    .div1 {
        background-image: url(../assets/PG6.png);
        background-size: contain;

        overflow: hidden;
    }

    .div2 {
        background-image: url(../assets/PG7.png);
        background-size: contain;
        overflow: hidden;

    }

    .div3 {
        background-image: url(../assets/PG8.png);
        overflow: hidden;
        background-size: contain;
    }

    .div4 {
        background-image: url(../assets/PG9.png);
        overflow: hidden;
        background-size: contain;
    }

    /* @media screen and (max-width: 1578px) {
        .opp {
            transform: scale(0.92);
        }





    } */

    /* @media screen and (min-width: 1426px) and (max-width: 1510px) {} */
</style>