<template>
    <div>

      <div
        style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
        <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../../assets/智慧高速.png" alt="">
       
        <p
          style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
          智慧高速
        </p>

        <p
          style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
        </p>
      </div>


      <div style="width: 6.5104rem;margin:0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }"> 首页 </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/SoluTion'}"> 解决方案</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/samCov18'}"> 智慧高速</el-breadcrumb-item>
        </el-breadcrumb>
      </div>


      <div  style="display: flex; justify-content: center; margin-top: .4167rem;">

        <div>

          <img src="../../assets/suidao8.webp" alt="" style="height: 1.5625rem;">
        </div>
        <div style="width:2.7344rem; margin-left: .2604rem; position: relative;">
          <img src="../../assets/fenghai2.png" alt="" style="vertical-align: middle;margin-left: .0781rem;">
          <span
               style=" font-weight:bold;font-size: .1667rem; font-family: Fontquan-XinYiGuanHeiTi, Fontquan-XinYiGuanHeiTi; color: #407BF5;vertical-align: middle; margin-left: .125rem;">隧道紧急停车带预警系统</span>
          <p style="color:#333;margin-top: .2083rem; font-size: .0833rem;">
            隧道紧急停车带预警系统采用高线束混合固态激光雷达和摄像机实时采集获取隧道紧急停车带信息，实时分析停车带车辆位置关系，并将信息通过以太网传输至平台。若检测到停车带有车，平台控制LED可变信息情报板发出预警提示，提醒正常行驶的车辆紧急停车带有车或可能会有车辆驶出，降低安全事故的发生，大大提高了隧道行车的安全性。
          
          </p>

            <div style="margin: 0 auto;position: absolute;bottom: .1042rem;right: 0;" class="chan">
              <ul style="list-style: none;display: flex;justify-content: flex-start;font-size: .1042rem;">
                <li @click="changeColor(0)" :style="{backgroundColor: activeIndex === 0 ? '#407BF5' : ''}">联系我们</li>
                <li @click="changeColor(1)" :style="{backgroundColor: activeIndex === 1 ? '#407BF5' : ''}">了解详情</li>
              </ul>
            </div>
        </div>


        <!-- font-family: 'HarmonyOS_Sans_SC_Regular'; -->

      </div>

      <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
        详细方案</div>
  
      <!--  style="width: 6.5104rem;margin: 0 auto;"-->
      <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
        <div class="xitong" >
         
          <p>系统布设</p>
        </div>
        <p style="margin-top: .0521rem;">a)当车辆驶入停车区，雷达和摄像机检测到停车事件触发信号继电器产生I/O电平信号到电平检测单元。</p>
        <p style="margin-top: .0521rem;">b)电平检测单元检测到信号，将检测到有车辆停靠的信息上传至云平台控制中心，同时通过智慧工作站信息联动通知前方一块或多块情报板，插播内置节目。</p>
        
        <img src="../../assets/suidao15.webp" alt="" style="width:6.5104rem;">
        <div class="xitong">
          <p >系统架构</p>
        </div>
        <img src="../../assets/suidao20.webp" alt="" style="margin-top: .0521rem;height: 1.5625rem;">
  
      </div>
  
      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem;border-bottom: #eee solid .0052rem;">
      </div>
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
        <el-button @click="goBack">返回列表</el-button>
      </div>
    </div>
  </template>

  <script>
    export default {
      data(){
        return{
          activeIndex: 0,
          // activeName: 'first',
        }

      },
      methods: {
        goBack() {
          history.back();
        },

        changeColor(index) {


          this.activeIndex = index;


          if (index === 0) {
            this.$router.push({ path: 'joinGo' }); // 跳转到名为'join'的路由
          }


          if (index === 1) {
            window.open('/pptx/丰海科技-隧道紧急停车带预警系统.pdf', '_blank');
          }
        },
   
  
        //       goTo(){
        //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
        //     const windowHeight = window.innerHeight; // 
        //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
        //     window.scrollTo(0, scrollTo); //
        //   },
      }
    }
  
  
  
  
  </script>
  
  <style scoped>
    .name {
  
      padding-bottom: .1042rem;
      border-bottom: #eeeeee solid .0052rem;
      font-size: .1042rem;
      color: #2056c0;
      font-weight: bold;
      position: relative;
  
    }
  
    .msgList {
      padding-top: .2604rem;
      /* border-bottom: #eee solid .0052rem; */
    }
  
    .msgList li {
      border: #c6c6c6 solid .0052rem;
      padding: .0521rem .1042rem .0521rem 1.0938rem;
      position: relative;
      font-size: .0729rem;
      margin-bottom: .0521rem;
      text-align: center;
      line-height: .2604rem;
    }
  
    li {
      list-style-position: outside;
      list-style-type: none;
    }
  
    .msgList li .msg {
      line-height: .1667rem;
      color: #999;
    }
  
    .content {
      padding-top: .1563rem;
      font-size: .0729rem;
      line-height: .125rem;
      color: #434343;
  
    }
  
    .tel {
      padding-top: .0521rem;
      font-size: .1875rem;
      color: #bb3944;
      font-family: Impact;
    }
  
    .txt {
      position: absolute;
      left: 0rem;
      top: 0rem;
      width: .9896rem;
      height: 100%;
      text-align: center;
      color: #FFFFFF;
      background: #2056c0;
      font-size: .0729rem;
      font-family: Arial, "微软雅黑";
  
    }
  
  
    .tittle {
      margin-top: .5208rem;
      height: .2083rem;
      text-align: center;
      line-height: .2083rem;
      font-weight: bold;
      font-size: .0833rem;
      color: #fff;
      position: relative;
    }
  
    .chan li {
      width: 1.0417rem;
      height: .2083rem;
      text-align: center;
      line-height: .2083rem;
      font-weight: bold;
      background-color: #E1E1E1;
    }
  
    .xitong{
      background-image: url(../../assets/xitong.png);
      background-size: cover;
      width: 1.0938rem;
      height: .2344rem;
      transform: translateY(-0.1042rem);
      margin-top: .0781rem;
  }
  .xitong p {
    text-align: center;
    line-height: .2344rem;
    color: #407BF5;
    font-size: .1042rem;
    font-weight: bold;
    letter-spacing: .0521rem;
  
  }
  
  
  
  </style>