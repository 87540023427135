<template>
    <div>
      <!-- 面包屑导航 -->
      <div style="width: 6.5104rem; margin: 0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/civilianProducts', query: { tab: 'first' }}">雷达传感器</el-breadcrumb-item>
          <el-breadcrumb-item>60GHz系列</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  

  
      <!-- 返回按钮 -->
      <div style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;font-weight: bold;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
    </div>
  </template>
  
  
  <script>
  
  export default {
    data() {
      return {
        activeTab: "page1", // 默认显示页面1
      };
    },
    methods: {
      goBack() {
        this.$router.push({ path: "/civilianProducts", query: { tab: 'first' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - windowHeight / 2; // 计算滚动距离  
        window.scrollTo(0, scrollTo); // 滚动到目标位置
      },
      switchPage(tab) {
        this.activeTab = tab; // 切换页面
      },
    },
  };
  
  </script>
  
  
  <style scoped>
  .tel {
          padding-top: .0521rem;
          font-size: .1875rem;
          color: #bb3944;
          font-family: Impact;
      }
  
  .myUl li{
          height:.2344rem;
          margin-top: .0625rem;
      }
  /* 样式调整 */
  .content {
    padding-top: .1563rem;
    font-size: .0729rem;
    line-height: .125rem;
    color: #434343;
    text-align: center;
  }
  
  h3 {
    font-size: 1rem;
    color: #2056c0;
  }
  
  p {
    font-size: 0.875rem;
    color: #666;
  }
  </style>
  
  