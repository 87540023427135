import { render, staticRenderFns } from "./mationBan16.vue?vue&type=template&id=18374bce&scoped=true"
import script from "./mationBan16.vue?vue&type=script&lang=js"
export * from "./mationBan16.vue?vue&type=script&lang=js"
import style0 from "./mationBan16.vue?vue&type=style&index=0&id=18374bce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18374bce",
  null
  
)

export default component.exports