<template>

  <div>
    <div style="font-family: Arial, '微软雅黑';font-size:.0729rem; color: #999;padding-top: .1042rem;
    line-height: .125rem;">
      <P style="font-size: .125rem;width: 100%;flex-wrap: wrap;margin-top: .3125rem;
      color: #333;
      text-align: center;">成立海外业务部，进军国际市场
      </P>

      <p style="width: 100%;
      text-align: center;font-size: .0625rem;
      margin-top: .0781rem;"> 2017-06-06
      </p>

      <div style="width:6.5104rem;margin: 0 auto;padding-bottom: .0781rem; border-bottom: #eee solid .0052rem;">
      </div>

      <div
        style="width:6.5104rem;color: #666666;font-size: .0833rem; font-family: 'HarmonyOS_Sans_SC_Regular'; margin: 0 auto;padding-top: .1302rem;padding-bottom: .6771rem;border-bottom: #eee solid .0052rem;position: relative;">
        <p style="text-indent: .1563rem;">

          5月底，广州市丰海科技股份有限公司海外业务部正式成立，这是我们在“走出国门，
          布局海外”的道路上迈出的第一步，
          成立海外业务部也是公司创新发展模式、提升竞争能力的迫切要求。
          我们将努力探索，积极开拓，争取早日让我们的产品走出国门，走向世界。
        </p>
        <p style="text-indent: .1563rem;">
        </p>

        <div style="position: absolute; bottom: 0;right: 0;">
          <div class="social-share" data-initialized="true">
            <span style="margin-right: .0521rem;"> 分享到: </span>

            <a href="http://www.weibo.com" class="social-share-icon icon-weibo"></a>
            <a href="#" class="social-share-icon icon-qq"></a>
            <a href="#" class="social-share-icon icon-qzone"></a>
            <a href="#" class="social-share-icon icon-wechat"></a>

          </div>
        </div>
      </div>


      <div style="display: flex;justify-content: center;margin-top: .1302rem; margin-bottom: .1302rem;">
        <el-button @click="goBack"> 返回列表 </el-button>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    methods: {
      goBack() {
        history.back();
      }
    }
  }
  
</script>