<template>
    <div class="contain">
        <div
            style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
            <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/total1.png" alt="">
            <p
                style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
                解决方案</p>
            <p
                style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
            </p>
        </div>
        <!-- 小标题 -->

        <div
            style="font-family: PingFang SC, PingFang SC; width: 100%; height: .7865rem; background-color: #E9E9E9; display: flex; justify-content: center; align-items: center;">

            <div @mouseover="changeTa" @mouseout="changeOut" @click="goTosam"
                style="width: 1.6146rem; height: .625rem; background-color: #fff; margin-left: .1354rem;">
                <img src="../assets/mengb6.png" alt="" style="float: left;height: .625rem;">
                <p class="firP" style="font-size: .0938rem;font-weight: bold;
  color: #407BF5; margin-top: .1823rem;">智慧高速<i class="el-icon-right"></i></p>
                <p class="seodP" style="font-size: .0729rem;font-weight: 400;
  color: #5B5B5B;">可感知、可计算、可决策、可服务的智慧高速解决方案</p>
            </div>

            <div @mouseover="changeTa" @mouseout="changeOut" @click="goTosama"
                style="width: 1.6146rem; height: .625rem; background-color: #fff;margin-left: .1354rem;">
                <img src="../assets/mengb9.png" alt="" style="float: left;height: .625rem;">
                <p class="firP" style="font-size: .0938rem;font-weight: bold;
color: #407BF5; margin-top: .1823rem;">智慧家居<i class="el-icon-right"></i></p>
                <p class="seodP" style="font-size: .0729rem;font-weight: 400;
color: #5B5B5B;">智能化家居管理系统，打造智慧生活新体验</p>
            </div>



            <div @mouseover="changeTa" @mouseout="changeOut" @click="goTosam2"
                style="width: 1.6146rem; height: .625rem; background-color: #fff; margin-left: .1354rem;">
                <img src="../assets/mengb7.png" alt="" style="float: left;height: .625rem;">
                <p class="firP" style="font-size: .0938rem;font-weight: bold;
  color: #407BF5; margin-top: .1823rem;">智慧城市<i class="el-icon-right"></i></p>
                <p class="seodP" style="font-size: .0729rem;font-weight: 400;
  color: #5B5B5B;">信息化、智慧化、人性化等手段发展城市管理生态系统</p>
            </div>

            <!-- <div @mouseover="changeTa" @mouseout="changeOut" @click="goTosam3"
                style="width: 310px; height: 120px; background-color: #fff; margin-left: 26px;position: relative;">
                <img src="../assets/mengb8.png" alt="" style="float: left;position: absolute;">
                <p class="firP" style="font-size: 18px; font-weight: bold;
  color: #407BF5; margin-top: 35px;margin-left: 118px;">智慧隧道<i class="el-icon-right"></i></p>
                <p class="seodP" style="font-size: 14px;font-weight: 400;margin-left: 118px;
  color: #5B5B5B;">基于AI学习、雷视融合、大数
                    据、GIS等技术提升隧道管控</p>
            </div> -->

            <div @mouseover="changeTa" @mouseout="changeOut" @click="goTosama5" style="width: 1.6146rem; height: .625rem; background-color: #fff;margin-left: .1354rem;">
                <img src="../assets/mengb9.png" alt="" style="float: left;height: .625rem;">
                <p class="firP" style="font-size: .0938rem;font-weight: bold;
  color: #407BF5; margin-top: .1823rem;">智慧排班<i class="el-icon-right"></i></p>
                <p class="seodP" style="font-size: .0729rem;font-weight: 400;
  color: #5B5B5B;">面向警情“大数据”的智能调度系统及其缉私系统</p>
            </div>


        </div>

        <div style="width: 100%;">
            <div
                style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
                <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/热门解决方案.png" alt="">


                <!-- <P style="width: 286px;
                height: 36px;
                font-family: Arial, Arial;
                font-weight: 400;
                font-size: 32px;
                color: #1c4ef02b;
                line-height: 0px;
                text-align: left;
                font-style: normal;
                text-transform: none;transform: translateY(21px)  translateX(36px);">Popular &nbsp;&nbsp;solutions</P> -->

                <p
                    style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
                    热门解决方案</p>
                <p
                    style=" display: inline-block; height: .0104rem; width: .3333rem; background-color:  #407BF5 ; font-size: .1667rem ; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
                </p>

            </div>

        </div>
               
                <!-- <div style="width: 1280px;margin: 0 auto;">
                    <el-carousel :interval="4000" type="card" height="480px">
                        <el-carousel-item v-for="item in 3" :key="item" style="background-color: #ffffff;">
                            <div v-if="item === 1" class="pol1">
                                <img src="../assets/1sotion.webp" alt=""
                                    style="width:512px;height: 380px;transform: translateX(-11px) translateY(50px)">


                            </div>

                            <div v-if="item === 2" class="pol2" style="background-color: #fff;">
                                <img src="../assets/2sition.png" alt=""
                                    style="width:512px;height: 380px;transform: translateX(-11px) translateY(50px)">



                            </div>

                            <div v-if="item === 3" class="pol3" style="background-color: #fff;">
                                <img src="../assets/3sotion.webp" alt=""
                                    style="width:512px;height: 380px;transform: translateX(-11px) translateY(50px)">



                            </div>

                        </el-carousel-item>
                    </el-carousel>
                </div> -->

                <div style="height: 2.3438rem;margin-top: .4167rem;" class="newcen">


                    <el-carousel :interval="400000" type="card" height="1.9792rem" style="transform: translateX(-0.7813rem)  translateY(-0.1406rem) ;padding:0 1.5625rem; width: 5.2083rem">
                      
                      <el-carousel-item>
                        <div style="position: relative;">
                          <!-- <div style="height:32px;width:100%;background-color:  #1A409F;">
                        </div> -->
                          <!-- <div style="background-color:#5B5B5B ;width: 108px;height: 40px;font-size:15px ;font-weight: bold;color: #fff;text-align: center;">展会活动</div> -->
              
              
                          <img style="height:1.9792rem;width: 100%;" src="../assets/sotion111.webp" alt="">

                          <div>
                            <p style="font-size:.1458rem;top: .0677rem;position: absolute;left: .3125rem;color: #fff;font-weight: bold;"> 隧道紧急停车带智慧预警系统 </p>
                        </div>

                        <div style="position: absolute;width: .9375rem;height: 1.0156rem;right: .0781rem;top: .5rem;font-size: .0625rem;">
                            隧道紧急停车带预警系统采用智能感知单元实时监测停车带车辆信息与位置信息，并将信息通过以太网传输至智慧工作站。若检测到停车带有车，智慧工作站自动控制LED可变信息情报板插播预警提示，提醒正常行驶的车辆紧急停车带有车或可能会有车辆驶出，降低安全事故的发生，大大提高了隧道行车的安全性。


                        </div>
                           
                          
                      
                        </div>
                      </el-carousel-item>
                      <el-carousel-item >
                       
                          <!-- <div style="height:32px;width:100%;background-color:  #1A409F;">
                          <div style="background-color:#5B5B5B ;width: 108px;height: 40px;font-size:15px ;font-weight: bold;color: #fff;text-align: center;">展会活动</div>
                        
                        </div> -->
                        <div style="position: relative;">
                            <!-- <div style="height:32px;width:100%;background-color:  #1A409F;">
                          </div> -->
                            <!-- <div style="background-color:#5B5B5B ;width: 108px;height: 40px;font-size:15px ;font-weight: bold;color: #fff;text-align: center;">展会活动</div> -->
                
                
                            <img style="height:1.9792rem;width: 100%;" src="../assets/sotion222.webp" alt="">
  
                            <div>
                              <p style="font-size:.1458rem;top: .0677rem;position: absolute;left: .3125rem;color: #fff;font-weight: bold;"> 雾天公路行车安全诱导系统 </p>
                          </div>
  
                          <div style="position: absolute;width: .9375rem;height: 1.0156rem;right: .0781rem;top: .5rem;font-size: .0625rem;">
                            雾天公路行车安全诱导系统是专门针对雾天、夜晚及其他恶劣天气条件下道路行车安全保障而研制的新型智能化产品。产品重点针对高速公路及普通公路的雾区预警防撞，它具有闭环自动控制能力，系统具备能见度检测、主动诱导、防撞预警等功能。检测及诱导设备安装在道路两侧，采用太阳能供电及无线双频控制方式，能够根据天气情况自动启动相应的策略模式。
  
  
                          </div>
                             
                            
                        
                          </div>
                   
                        
                      </el-carousel-item>
              
                      <el-carousel-item>
                        
              
                        <div style="position: relative;">
                            <!-- <div style="height:32px;width:100%;background-color:  #1A409F;">
                          </div> -->
                            <!-- <div style="background-color:#5B5B5B ;width: 108px;height: 40px;font-size:15px ;font-weight: bold;color: #fff;text-align: center;">展会活动</div> -->
                            <img style="height:1.9792rem;width: 100%;" src="../assets/sotion333.webp" alt="">
  
                            <div>
                              <p style="font-size:.1458rem;top: .0677rem;position: absolute;left: .3125rem;color: #fff;font-weight: bold;"> 视频监控-AI智能监测系统 </p>
                          </div>
  
                          <div style="position: absolute;width: .9375rem;height: 1.0156rem;right: .0781rem;top: .5rem;font-size: .0625rem;">
                            基于视频监控大数据的视频监控AI智能监测系统;
                            对监控区域进行暴力行为检测，通过智能分析把控整个监控区域的安全，及时预警，在集市、建筑空地、校园、娱乐场所等重点区域得到广泛应用。
                          </div>
                             
                            
                        
                          </div>
                          
                    
                      </el-carousel-item>
                    </el-carousel>

               
                </div>
            
            <div>
            </div>
     

        <!--  -->















        <div
            style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
            <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/智慧高速.png" alt="">
            <p
                style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
                智慧高速</p>
            <p
                style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
            </p>
        </div>

        <div style="width: 100%;margin-top: .0521rem; display: flex; justify-content: center; align-items: center;">
            <div @click="handleClick" @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 1.6146rem; height: 1.474rem; position: relative; overflow: hidden;">

                <img src="../assets/501.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p
                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 1;width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">
                    智能防疲劳安全预警系统</p>
            </div>



            <div @click="handleClick1" @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 1.6146rem; height: 1.474rem;   margin-left: .1354rem;position: relative;overflow: hidden;">
                <img src="../assets/502.webp" alt="" style="float: left;">
                <p
                    style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem; font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">
                    行人摩托车闯入智能预警系统</p>
            </div>
            <div @click="handleClick2" @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 1.6146rem; height: 1.474rem;    margin-left: .1354rem;position: relative; overflow: hidden;">
                <img src="../assets/503.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">雾天公路行车安全诱导系统</p>
            </div>
            <div @click="handleClick3" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem;   margin-left: .1354rem;position: relative;">
                <img src="../assets/504.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;white-space: normal;box-sizing: border-box;">低位照明—路灯“隐身术”，舒适道路</p>
            </div>
        </div>

        <div style="width: 100%; margin-top: .0781rem;  display: flex; justify-content: center; align-items: center;">
            <div @click="handleClick4" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem; position: relative;">
                <img src="../assets/509.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">隧道紧急停车带预警系统</p>
            </div>
            <div @click="handleClick5" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem;   margin-left: .1354rem;position: relative;">
                <img src="../assets/510.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">高速隧道团雾解决方案</p>
            </div>
            <div @click="handleClick6" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem;    margin-left: .1354rem;position: relative;">
                <img src="../assets/511.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">隧道水幕智能预警方案</p>
            </div>
            <div @click="handleClick7" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem;   margin-left: .1354rem;position: relative;">
                <img src="../assets/512.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">隧道车流量统计方案</p>
            </div>
        </div>


        <div
            style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
            <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/智慧家居.png" alt="">
            <p
                style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
                智慧家居</p>
            <p
                style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
            </p>
        </div>

        <div style="width: 100%; margin-top: .0521rem;  display: flex; justify-content: center; align-items: center;">
            <div @click="handleClick13" @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 1.6146rem; height: 1.474rem; position: relative;overflow: hidden;">
                <img src="../assets/zhihuijiaju3.png" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">智慧家居</p>
            </div>
            <!-- <div style="width: 310px; height: 283px; background-color: #fff; margin-left: 26px;position: relative;">


            </div>
            <div style="width: 310px; height: 283px; background-color: #fff; margin-left: 26px;position: relative;">

            </div>
            <div style="width: 310px; height: 283px; background-color: #fff; margin-left: 26px;position: relative;">

            </div> -->
        </div>

        <!--  -->
        <div
            style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
            <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/智慧城市.png" alt="">
            <p
                style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
                智慧城市</p>
            <p
                style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
            </p>
        </div>

        <div style="width: 100%;margin-top: .0521rem; display: flex; justify-content: center; align-items: center;">

            <div @click="handleClick9" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem;   margin-left: .1354rem;position: relative;">
                <img src="../assets/506.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">森林防火监控工程</p>
            </div>
            <div @click="handleClick10" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem;    margin-left: .1354rem;position: relative;">
                <img src="../assets/507.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">易涝点预警工程</p>
            </div>
            <div @click="handleClick11" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem;   margin-left: .1354rem;position: relative;">
                <img src="../assets/508.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">交通道路整治工程</p>
            </div>
        </div>

        <!--  -->


        <!-- <div style="width: 100%; margin-top: 10px;  display: flex; justify-content: center; align-items: center;">
            <div @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 310px; height: 283px; position: relative;">
                <img src="../assets/509.png" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: 40px; position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px;
   font-weight: 400;padding-left: 10px;box-sizing: border-box;">隧道紧急停车带预警系统</p>
            </div>
            <div @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 310px; height: 283px;   margin-left: 26px;position: relative;">
                <img src="../assets/510.png" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: 40px; position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px;
   font-weight: 400;padding-left: 10px;box-sizing: border-box;">高速隧道团雾解决方案</p>
            </div>
            <div @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 310px; height: 283px;    margin-left: 26px;position: relative;">
                <img src="../assets/511.png" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: 40px; position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px;
   font-weight: 400;padding-left: 10px;box-sizing: border-box;">隧道水幕智能预警方案</p>
            </div>
            <div @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 310px; height: 283px;   margin-left: 26px;position: relative;">
                <img src="../assets/512.png" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: 40px; position: absolute;bottom: 0;font-size: 18px;color: #FFFFFF; line-height: 40px;
   font-weight: 400;padding-left: 10px;box-sizing: border-box;">隧道车流量统计方案</p>
            </div>
        </div> -->

        <!--  -->


        <div
            style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
            <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/智慧排班.png" alt="">
            <p
                style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
                智慧排班</p>
            <p
                style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5 ; font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
            </p>
        </div>



        <div style="width: 100%; margin-top: .0521rem;  display: flex; justify-content: center; align-items: center;">
            <div @click="handleClick8" @mouseover="chaImg" @mouseout="chaoutImg"
                style="overflow: hidden;width: 1.6146rem; height: 1.474rem; position: relative;">
                <img src="../assets/505.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">视频监控—AI智能监测系统</p>
            </div>
            <div @click="handleClick12" @mouseover="chaImg" @mouseout="chaoutImg"
                style="width: 1.6146rem; height: 1.474rem; position: relative;overflow: hidden; margin-left: .1354rem;">
                <img src="../assets/517.webp" alt=""
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">智慧交警AI调度系统</p>

            </div>
            <!-- <div style="width: 1.6146rem; height: 1.474rem; background-color: #fff; margin-left: .1354rem;position: relative;">


            </div>
            <div style="width: 1.6146rem; height: 1.474rem; background-color: #fff; margin-left: .1354rem;position: relative;">

            </div>
            <div style="width: 1.6146rem; height: 1.474rem; background-color: #fff; margin-left: .1354rem;position: relative;">

            </div> -->
        </div>
        <!--  -->

        <div
            style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
            <img style="margin: 0 auto;position: absolute;height: .1875rem;" src="../assets/客户案例.png" alt="">
            <p
                style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0938rem;">
                客户案例</p>
            <p
                style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0417rem;">
            </p>
        </div>

        <div
            style="width: 100%; margin-top: .0521rem;  display: flex; justify-content: center; align-items: center;margin-bottom: .1563rem;">
            <div @mouseover="chaImg" @mouseout="chaoutImg" @click="handleClick4"
                style="width: 1.6146rem; height: 1.474rem;position: relative;overflow: hidden;">
                <img src="../assets/514.webp" alt="" style="float: left;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">隧道紧急停车带智慧预警系统</p>
            </div>
            <div @mouseover="chaImg" @mouseout="chaoutImg" @click="handleClick2"
                style="width: 1.6146rem; height: 1.474rem;margin-left: .1354rem;position: relative;overflow: hidden;">
                <img src="../assets/515.webp" alt="" style="float: left;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">雾天公路行车安全诱导系统</p>
            </div>
            <div @mouseover="chaImg" @mouseout="chaoutImg" @click="handleClick10"
                style="width: 1.6146rem; height: 1.474rem;  margin-left: .1354rem;position: relative;overflow: hidden;">
                <img src="../assets/516.webp" alt="" style="float: left;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">隧道易涝点预警工程</p>
            </div>
            <div @mouseover="chaImg" @mouseout="chaoutImg" @click="handleClick12"
                style="width: 1.6146rem; height: 1.474rem;  margin-left: .1354rem;position: relative;overflow: hidden;">
                <img src="../assets/517.webp" alt="" style="float: left;">
                <p style="background: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%);opacity: 0.8;
  width: 100%; height: .2083rem; position: absolute;bottom: 0;font-size: .0938rem;color: #FFFFFF; line-height: .2083rem;
   font-weight: 400;padding-left: .0521rem;box-sizing: border-box;">智慧交警AI调度系统</p>
            </div>
        </div>

    </div>


</template>

<script>


    export default {
        name: 'soluTion',
        components: {

        },
        data() {
            return {

            };
        },
        methods: {
            changeTa(event) {
                const cor = event.currentTarget.querySelector('i');
                cor.style.color = "#407BF5";
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.6s"; // 设置动画过渡时间为0.5秒
                img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
                const opo = event.currentTarget.querySelector('.firP');
                opo.style.fontSize = .1302 + "rem"; 
                opo.style.transition = "0.6s";
                const opoT = event.currentTarget.querySelector('.seodP');
                opoT.style.fontWeight = "bold";
                opoT.style.transition = "0.6s";

            },

            changeOut(event) {
                const cor = event.currentTarget.querySelector('i');
                cor.style.color = "gray"
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
                img.style.transform = "scale(1)"; // 设置图片放大1.2倍
                const opo = event.currentTarget.querySelector('.firP');
                opo.style.fontSize = .0938 + "rem"; 
                opo.style.transition = "0.5s";

                const opoT = event.currentTarget.querySelector('.seodP');
                opoT.style.fontWeight = null;
                opoT.style.transition = "0.5s";

            },

            chaImg(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
                img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
                img.style.overflow = "hidden"; // 超出部分隐藏
                const pl = event.currentTarget.querySelector('p');
                pl.style.transition = "0.2s";
                pl.style.fontSize = .0938 + "rem"; 
                pl.style.background = "linear-gradient(180deg, rgba(64, 123, 245, 0.8) 0%, rgba(42, 98, 200, 0.8) 100%)";



            },
            goTosama() {

                this.$router.push({ path: '/samCov17' });//之前是17

                // const windowHeight = window.innerHeight; // 获取窗口高度
                // const targetOffsetTop = 2000; // 设置目标元素距离页面顶部的偏移量
                // const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

                // // 添加动画效果
                // document.documentElement.style.scrollBehavior = 'smooth';
                // document.documentElement.style.opacity = 0.6; // 设置透明度为0
                // window.scrollTo(0, scrollTo); // 滚动到目标位置

                // // 恢复默认的滚动行为
                // setTimeout(() => {
                //     document.documentElement.style.scrollBehavior = 'auto';
                //     document.documentElement.style.opacity = 1; // 设置透明度为1
                // }, 1000);

            },
            goTosama5() {

                this.$router.push({ path: '/samCov14' });

                // const windowHeight = window.innerHeight; // 获取窗口高度
                // const targetOffsetTop = 2000; // 设置目标元素距离页面顶部的偏移量
                // const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

                // // 添加动画效果
                // document.documentElement.style.scrollBehavior = 'smooth';
                // document.documentElement.style.opacity = 0.6; // 设置透明度为0
                // window.scrollTo(0, scrollTo); // 滚动到目标位置

                // // 恢复默认的滚动行为
                // setTimeout(() => {
                //     document.documentElement.style.scrollBehavior = 'auto';
                //     document.documentElement.style.opacity = 1; // 设置透明度为1
                // }, 1000);
            },
            goTosam2() {
                this.$router.push({ path: '/samCov19' });
                // const windowHeight = window.innerHeight; // 获取窗口高度
                // const targetOffsetTop = 2400; // 设置目标元素距离页面顶部的偏移量
                // const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

                // // 添加动画效果
                // document.documentElement.style.scrollBehavior = 'smooth';
                // document.documentElement.style.opacity = 0.6; // 设置透明度为0
                // window.scrollTo(0, scrollTo); // 滚动到目标位置

                // // 恢复默认的滚动行为
                // setTimeout(() => {
                //     document.documentElement.style.scrollBehavior = 'auto';
                //     document.documentElement.style.opacity = 1; // 设置透明度为1
                // }, 1000);
            },
            goTosam3() {

                this.$router.push({ path: '/samCov20' });
                // const windowHeight = window.innerHeight; // 获取窗口高度
                // const targetOffsetTop = 2800; // 设置目标元素距离页面顶部的偏移量
                // const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

                // // 添加动画效果
                // document.documentElement.style.scrollBehavior = 'smooth';
                // document.documentElement.style.opacity = 0.6; // 设置透明度为0
                // window.scrollTo(0, scrollTo); // 滚动到目标位置

                // // 恢复默认的滚动行为
                // setTimeout(() => {
                //     document.documentElement.style.scrollBehavior = 'auto';
                //     document.documentElement.style.opacity = 1; // 设置透明度为1
                // }, 1000);
            },
            goTosam() {
                this.$router.push({ path: '/samCov18' });
                // const windowHeight = window.innerHeight; // 获取窗口高度
                // const targetOffsetTop = 3200; // 设置目标元素距离页面顶部的偏移量
                // const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

                // // 添加动画效果
                // document.documentElement.style.scrollBehavior = 'smooth';
                // document.documentElement.style.opacity = 0.6; // 设置透明度为0
                // window.scrollTo(0, scrollTo); // 滚动到目标位置

                // // 恢复默认的滚动行为
                // setTimeout(() => {
                //     document.documentElement.style.scrollBehavior = 'auto';
                //     document.documentElement.style.opacity = 1; // 设置透明度为1
                // }, 1000);
            },

            chaoutImg(event) {
                const img = event.currentTarget.querySelector('img');
                img.style.transition = "0.5s"; // 清除过渡效果
                img.style.transform = ""; // 清除变换效果
                img.style.overflow = ""; // 清除overflow属性

                const pl = event.currentTarget.querySelector('p');
                pl.style.opacity = "1";
                pl.style.fontSize = .0833 + "rem"; 
                pl.style.transition = "0.4s";
                pl.style.background = "linear-gradient(180deg, rgba(255,255,255,0.15) 0%, #37405E 100%)"
            },
            handleClick() {
                this.$router.push({ path: "/samCov" });
            },
            handleClick1() {
                this.$router.push({ path: "/samCov1" });
            },
            handleClick2() {
                this.$router.push({ path: "/samCov2" });
            },
            handleClick3() {
                this.$router.push({ path: "/samCov3" });
            },
            handleClick4() {
                this.$router.push({ path: "/samCov4" });
            },
            handleClick5() {
                this.$router.push({ path: "/samCov5" });
            },
            handleClick6() {
                this.$router.push({ path: "/samCov6" });
            },
            handleClick7() {
                this.$router.push({ path: "/samCov7" });
            },
            handleClick8() {
                this.$router.push({ path: "/samCov8" });
            },
            handleClick9() {
                this.$router.push({ path: "/samCov9" });
            },
            handleClick10() {
                this.$router.push({ path: "/samCov10" });
            },
            handleClick11() {
                this.$router.push({ path: "/samCov11" });
            },
            handleClick12() {
                this.$router.push({ path: "/samCov12" });
            },
            handleClick13() {
                this.$router.push({ path: "/samCov13" });
            }
        }
    };

</script>


<style scoped>
    /* .pol1{
        background-image: url(../assets/1sotion.png);width: 660px;height: 380px;background-size: cover;transform: translateX(-11px) translateY(50px)
    } */
    /* .pol2{
        background-image: url(../assets/2sition.png);width: 660px;height: 380px;background-size: cover;transform: translateX(-11px) translateY(50px);
    }
    .pol3{
        background-image: url(../assets/3sotion.png);width: 660px;height: 380px;background-size: cover;transform: translateX(-11px) translateY(50px);
    } */
    /* .contain /deep/ .el-icon-arrow-right {
        left: .0833rem;
        background-image: url(../assets/1818.png);
        background-size: 100%;
        width: .4167rem;
        height: .4167rem;
        transform: translate(-0.1563rem, -0.1302rem);
    }

    .contain /deep/ .el-icon-arrow-left {
        left: .0833rem;
        background-image: url(../assets/1817.png);
        background-size: 100%;
        width: .4167rem;
        height: .4167rem;
        transform: translate(-0.0781rem, -0.1302rem);
    } */

    h1 {
        color: blue;
    }



    /* .el-carousel__item:nth-child(2n) {
        background-color: gray;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: gray;
    } */

    
  .newcen /deep/ .el-carousel__arrow--right{
    transform: translateX(1.4583rem);
    background-image: url(../assets/1818.png);
    background-size: 100%;
  }
  .newcen /deep/ .el-carousel__indicators {
    transform: translateX(.7813rem);
  }

  .newcen /deep/ .el-carousel__arrow--left{
 
    background-image: url(../assets/1817.png);
    background-size: 100%;
  }

  @font-face {
    font-family: 'cc';
    src: url('../assets/font/PingFang Regular.ttf') format('truetype');
   
  }

</style>