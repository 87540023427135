<template>
    <div>
        <!-- 面包屑导航 -->
        <div style="width: 6.5104rem; margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/civilianProducts', query: { tab: 'third' }}">控制类系列</el-breadcrumb-item>
                <el-breadcrumb-item>线控器系列</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 按钮切换 -->
        <div style="display: flex; justify-content: left; width: 6.5104rem;margin: 0 auto;margin-top: .1042rem;">
            <!-- <el-button
                :style="{ backgroundColor: activeTab === 'page1' ? '#2056c0' : '' ,  color: activeTab === 'page1' ? '#fff' : '' }"
                @click="switchPage('page1')">
                IF-KJR-120L
            </el-button>

            <el-button
                :style="{ backgroundColor: activeTab === 'page2' ? '#2056c0' : '' ,  color: activeTab === 'page2' ? '#fff' : '' }"
                @click="switchPage('page2')">
                IF-KJR-120N/M
            </el-button>

            <el-button
                :style="{ backgroundColor: activeTab === 'page3' ? '#2056c0' : '' ,  color: activeTab === 'page3' ? '#fff' : '' }"
                @click="switchPage('page3')">
                IF-XKQ-120X
            </el-button> -->

            <el-button
                :style="{ backgroundColor: activeTab === 'page4' ? '#2056c0' : '' ,  color: activeTab === 'page4' ? '#fff' : '' }"
                @click="switchPage('page4')">
                无刷电机控制
            </el-button>
        </div>

        <!-- 页面内容显示 -->
        <div class="content">

            <div v-if="activeTab === 'page1'" style="margin: 0 auto;">
                <div style="display: flex;width: 6.5104rem; margin: 0 auto; justify-content: space-between;">
                    <div style="width:2.5rem;height:2.0104rem;">
                        <img style="height:100%;" src="../../assets/klm1.png" alt="">
                    </div>
                    <div style="width: 3.8281rem;color: #407BF5;">
                        <ul class="myUl" style="width: 100%; text-align: left; list-style: none;font-size: .0938rem;color: #434343;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;font-weight: bold;
                                font-size: .1667rem;
                                color: #407BF5;">
                                线控器IF-KJR-120L </li>







                            <li><span style="color: #407BF5;">◆</span> 材质：PC/ABS</li>
                            <li><span style="color: #407BF5;">◆</span>材质：PC/ABS</li>
                            <li><span style="color: #407BF5;">◆</span> 红外接收</li>
                            <li><span style="color: #407BF5;">◆</span> 自清洁</li>
                            <li><span style="color: #407BF5;">◆</span> 垂直摇摆</li>
                            <li><span style="color: #407BF5;">◆</span> 8度制热</li>
                            <li><span style="color: #407BF5;">◆</span> 工程模式</li>
                           
                        </ul>
                        <div style="margin-top: .1563rem;float: left;">
                            <div style="font-size: .0729rem;
                    color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>
                    </div>

                </div>
                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5;width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>

                    <div
                        style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

                        <div
                            style="width: 100%;border: .0052rem dashed #ccc;margin-top: .1563rem;display: flex;justify-content: space-between;">
                           

                            <div style="width: 45%;height: 3.1406rem;">
                           <ul style="text-decoration: none;list-style: none;text-align: left;text-indent: .1042rem;">
                            <li style="color: #407BF5;font-size: .1563rem;height: .3125rem;line-height: .3125rem;">智能家居场景</li>
                            <li>空调设备：多联式中央空调+新风+地暖</li>
                            <li><img src="../../assets/klm6.png" alt="" style="width: 100%;height:2.6563rem"></li>
                           </ul>

                            </div>

                            <div style="width: 45%;height: 3.1406rem;">
                                <img style="width: 100%;height: 100%;" src="../../assets/klm5.png" alt="">
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div v-if="activeTab === 'page2'" style=" margin: 0 auto;">
                <div style="display: flex;width: 6.5104rem;  margin: 0 auto;justify-content: space-between;">
                    <div style="width:3.3854rem;height: 2.0104rem;display: flex;justify-content: space-between;">
                         <img src="../../assets/klm2.png" style="width: 1.8594rem;height: 1.4948rem;transform: translateY(.1354rem)" alt="">
                        <img src="../../assets/klm7.png" style="width: 1.8594rem;height: 1.4948rem;scale: 0.65;transform: translateX(-.6771rem);" alt="">
                   </div>
                    <div style="width: 3.8281rem;color: #407BF5;">

                        <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;color: #434343;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: .0052rem solid #707070;color: #407BF5;">IF-KJR-120N/M</li>






                            <li><span style="color: #407BF5;">◆</span> 4.3寸液晶显示屏</li>
                            <li><span style="color: #407BF5;">◆</span>兼容无极性和485通讯自适应</li>
                            <li><span style="color: #407BF5;">◆</span> 环境：0℃-45℃，5-90%RH(不结露)</li>
                            <li><span style="color: #407BF5;">◆</span> 材质：PC/ABS</li>
                            <li><span style="color: #407BF5;">◆</span>功能：无风感，防直吹，智慧眼，滤网清洁提醒，ECO，水洗自清洁，WiFi、升降面板、湿度调节、增加负离子/UV、双周定时、恒定出风、随身感、睡眠、8度制热</li>
                        </ul>

                        <div style="margin-top: .1563rem;;float: left;">
                            <div style="font-size: .0729rem;
                    color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>
                    </div>
                </div>

                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>

                    <div
                        style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

                        <div
                            style="width: 100%;border: .0052rem dashed #ccc;margin-top: .1563rem;display: flex;justify-content: space-between;">
                           

                            <div style="width: 45%;height: 3.1406rem;">
                           <ul style="text-decoration: none;list-style: none;text-align: left;text-indent: .1042rem;">
                            <li style="color: #407BF5;font-size: .1563rem;height: .3125rem;line-height: .3125rem;">智能家居场景</li>
                            <li>空调设备：多联式中央空调+新风+地暖</li>
                            <li><img src="../../assets/klm6.png" alt="" style="width: 100%;height:2.6563rem"></li>
                           </ul>

                            </div>

                            <div style="width: 45%;height: 3.1406rem;">
                                <img style="width: 100%;height: 100%;" src="../../assets/klm5.png" alt="">

                            </div>
                        </div>

                    </div>

                </div>
            </div>


            <div v-if="activeTab === 'page3'" style=" width: 6.5104rem;margin: 0 auto;">
                <div style="display: flex; justify-content: space-between;">
                    <div style="width:2.5rem;height: 2.0104rem">
                        <img style="height:100%;scale: 0.80;" src="../../assets/klm8.png" alt="">
                    </div>
                    <div style="width: 3.8281rem;color: #407BF5;">

                        <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;color: #434343;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">
                                线控器
                                IF-XKQ-120X </li>
                            <li><span style="color: #407BF5;">◆</span> 瑞萨100FE芯片方案，自带程序</li>
                            <li><span style="color: #407BF5;">◆</span> 无极性/485通信</li>
                            <li><span style="color: #407BF5;">◆</span> 双周定时，有恒风量功能</li>
                            <li><span style="color: #407BF5;">◆</span> 120X：无极性，可一控16</li>
                            <li><span style="color: #407BF5;">◆</span> 120X1：5V，485通信（分体机专用）</li>
                            <li><span style="color: #407BF5;">◆</span> 120X2：12V，485通信</li>
                            <li><span style="color: #407BF5;">◆</span> 特殊功能：强劲（电辅热）、随身感、睡眠、8度制热</li>
             
             
                        </ul>

                        <div style="margin-top: .1563rem;float: left;">

                            <div style="font-size: .0729rem;
                      color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>

                    </div>
                </div>





                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>
                    <div
                        style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

                        <div
                            style="width: 100%;border: .0052rem dashed #ccc;margin-top: .1563rem;display: flex;justify-content: space-between;">
                           

                        <div style="width: 45%;height: 3.1406rem;">
                           <ul style="text-decoration: none;list-style: none;text-align: left;text-indent: .1042rem;">
                            <li style="color: #407BF5;font-size: .1563rem;height: .3125rem;line-height: .3125rem;">智能家居场景</li>
                            <li>空调设备：多联式中央空调+新风+地暖</li>
                            <li><img src="../../assets/klm6.png" alt="" style="width: 100%;height:2.6563rem"></li>
                           </ul>
                        </div>

                            <div style="width: 45%;height: 3.1406rem;">
                                <img style="width: 100%;height: 100%;" src="../../assets/klm5.png" alt="">
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeTab === 'page4'" style=" width: 6.5104rem;margin: 0 auto;">
              
                <div style="display: flex; justify-content: space-between;">
                    <div style="width:2.5rem;height: 2.0104rem;">
                        <img style="height:100%;" src="../../assets/klm4.png" alt="">
                    </div>

                    <div style="width: 3.8281rem;color: #407BF5;">
                        <ul class="myUl" style="width: 100%; text-align: left;list-style: none; font-size: .0938rem; color: #434343;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">
                                无刷电机控制
                            </li>

                            <li><span style="color: #407BF5;">◆</span> 支持直流无刷电机 BLDC</li>
                            <li><span style="color: #407BF5;">◆</span> 支持永磁同步电机 PMSM</li>
                            <li><span style="color: #407BF5;">◆</span> 支持方波控制</li>
                            <li><span style="color: #407BF5;">◆</span> 支持FOC控制</li>
                            <li><span style="color: #407BF5;">◆</span> 支持多种限位输入</li>
                            <li><span style="color: #407BF5;">◆</span> 支持行程学习</li>
                            <li><span style="color: #407BF5;">◆</span> 支持缓启缓停</li>
                            <li><span style="color: #407BF5;">◆</span> 支持串口通信</li>
                        </ul>

                        <div style="margin-top: .1563rem;float: left;">
                            <div style="font-size: .0729rem;
                      color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>
                    </div>
                </div>

                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>
                    <div style="text-align: left;margin-top: .0781rem;"> 智能家居窗帘控制器</div>
                    <div style="  margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;display: flex;justify-content: space-between;">
                       

                    
                        <div style="width:4.625rem; height:3.8594rem; margin-top: .1302rem; border: .0052rem dashed #ccc; position: relative; border-radius: 4px; padding: 4px; box-sizing: border-box;">
                            <img style="width: 100%; height: 100%;" src="../../assets/klm9.png" alt="">
                            <ul style="position: absolute;font-size: .0938rem;font-family: PingFang SC-Medium; bottom: .0781rem; right: .0781rem;  list-style: none; margin: 0; padding: .026rem; border-top-left-radius: .0208rem;text-align: left;">
                              <li> 支持zigbee通信</li>
                              <li>集成开关电源体积小</li>
                              <li>FOC控制算法，可在低转速下也能输出大转矩</li>
                            </ul>
                          </div>
                        <div style="width:2.1042rem;height: 3.8594rem;margin-left: .1563rem;">

                            <div style="width: 100%;height: 1.8646rem;margin-top: .1458rem;">
                                <img src="../../assets/klm10.png" alt="" style="width: 100%; height: 100%;"> 
                            </div>

                            <div style="width: 100%;height: 1.8646rem;margin-top: .1354rem;"> 
                                <img src="../../assets/klm11.png" alt="" style="width: 100%; height: 100%;"> 
                            </div>

                        </div>
                       </div>
             


                    

                </div>
            </div>


        </div>

        <div
            style="background-color: #f4f4f4; width: 100%; padding-top: .1563rem; box-sizing: border-box;margin-top: .1563rem;">
            <p
                style="font-size: .1667rem; height: .1563rem; width: 6.5104rem; text-align: left; margin: 0 auto; color:#407bf5; font-family: PingFang SC;font-weight: bold;">
                
                产品列表
            </p>

            <!-- 产品展示区 -->

            <div style="width: 6.5104rem; margin: 0 auto; margin-top: .1563rem; overflow: hidden;">
                <div style="display: flex; transition: transform 0.3s ease-in-out;"
                    :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                    <div v-for="(page, pageIndex) in paginatedProducts" :key="pageIndex"
                        style="display: flex; flex-shrink: 0; width: 100%;">
                        <div v-for="(item, index) in page" :key="index"
                            style="width: 2.0833rem; height: 2.0833rem; background-color: #FFF; margin-left: .1042rem;">
                            <img style="width: 100%;height: 100%;" :src="item.img" alt="">
                            <p style="text-align: center; font-size: .0625rem;transform: translateY(-.1563rem);">
                                {{item.name }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- 分页指示器 -->
            <div style="display: flex; justify-content: center; margin-top: .0521rem;">
                <span v-for="(dot, index) in paginatedProducts.length" :key="index" @click="goToPage(index)"
                    style="width: .0417rem; height: .0417rem; border-radius: 50%; margin: 0 .026rem; cursor: pointer;"
                    :style="{ backgroundColor: index === currentIndex ? ' #407BF5' : '#ccc' }">
                </span>
            </div>
        </div>


        <!-- 返回按钮 -->
        <div
            style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem ; font-weight: bold; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>

    </div>
</template>


<script>
    export default {
        data() {
            return {
                activeTab: "page1", // 默认显示页面1
                products: [
                    // { name: "IF-KJR-120L", img: require("@/assets/klm1.png") },
                    // { name: "IF-KJR-120N/M", img: require("@/assets/klm2.png") },
                    // { name: "IF-XKQ-120X", img: require("@/assets/klm3.png") },
                    { name: "无刷电机控制", img: require("@/assets/klm4.png") },
                ],
                itemsPerPage: 3,
                currentIndex: 0

            };
        },
        created() {
    if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
    }},

        computed: {
            paginatedProducts() {
                return this.products.reduce((result, item, index) => {
                    const pageIndex = Math.floor(index / this.itemsPerPage);
                    if (!result[pageIndex]) result[pageIndex] = [];
                    result[pageIndex].push(item);
                    return result;
                }, []);
            }
        },

        methods: {
            goToPage(index) {
                this.currentIndex = index;
            },

            goBack() {
                this.$router.push({ path: "/civilianProducts", query: { tab: 'third' } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - windowHeight / 2; // 计算滚动距离  
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },

            switchPage(tab) {
                this.activeTab = tab; // 切换页面
            },
        },
    };
</script>


<style scoped>
    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .myUl li {
        
        margin-top: .0625rem;
    }

    /* 样式调整 */
    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;
        text-align: center;
    }

    h3 {
        font-size: 1rem;
        color: #2056c0;
    }

    p {
        font-size: 0.875rem;
        color: #666;
    }
</style>
