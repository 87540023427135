<template>
    <div>
        <!-- 面包屑导航 -->
        <div style="width: 6.5104rem; margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/civilianProducts', query: { tab: 'second' }}">通信模组系列</el-breadcrumb-item>
                <el-breadcrumb-item>通信模组</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 按钮切换 -->
        <div style="display: flex; justify-content: left; width: 6.5104rem;margin: 0 auto;margin-top: .1042rem;">

            <!-- <el-button
                :style="{ backgroundColor: activeTab === 'page1' ? '#2056c0' : '' ,   color: activeTab === 'page1' ? '#fff' : '' }"
                @click="switchPage('page1')">
                433MHz通信模组
            </el-button> -->

            <el-button
                :style="{ backgroundColor: activeTab === 'page2' ? '#2056c0' : '' ,  color: activeTab === 'page2' ? '#fff' : '' }"
                @click="switchPage('page2')">
                433/868/915MHz通信模组
            </el-button>

            <el-button
                :style="{ backgroundColor: activeTab === 'page3' ? '#2056c0' : '' ,  color: activeTab === 'page3' ? '#fff' : '' }"
                @click="switchPage('page3')">
                4G LTE数传模组
            </el-button>

            <el-button
                :style="{ backgroundColor: activeTab === 'page4' ? '#2056c0' : '' ,  color: activeTab === 'page4' ? '#fff' : '' }"
                @click="switchPage('page4')">
                LoRa透传模组
            </el-button>

            <el-button
                :style="{ backgroundColor: activeTab === 'page5' ? '#2056c0' : '' ,  color: activeTab === 'page5' ? '#fff' : '' }"
                @click="switchPage('page5')">
                蓝牙模组
            </el-button>
        </div>

        <!-- 页面内容显示 -->

        <div class="content">
            <div v-if="activeTab === 'page1'" style="margin: 0 auto;">
                <div style="display: flex;width: 6.5104rem; margin: 0 auto;justify-content: space-between;">
                    <div style="width:2.5rem;height:2.0104rem;">
                        <img style="width: 100%;height:100%;scale: 0.80;transform: translateY(1.0417rem);" src="../../assets/kq1.png" alt="">
                    </div>
                    <div style="width: 3.8281rem;color: #407BF5;">
                        <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;font-size: .0938rem;color: #37405E;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: .0052rem solid #707070;color: #407BF5;">
                                433MHz通信模组 </li>
                            <li style="font-weight: bold;
                            font-size: .125rem;
                            color: #407BF5;">大功率无线收发模块</li>
                            <li style="font-weight: 500;
                            font-size: .0938rem;
                            color: #37405E;">频率范围：433/868/915MHz频段</li>
                            <li style="font-weight: bold;
                            font-size: .125rem;
                            color: #407BF5;">产品特性</li>
                            <li style="width:3.0833rem;height:1.026rem;"><img src="../../assets/kq2.png"
                                    style="width: 100%;height: 100%;" alt=""></li>
                            <li style="font-weight: 500;
                            font-size: .0938rem;
                            color: #37405E;">IF400TU模块载SI4463收发器，采用FSK/ASK技术设计，完美解决了小数据量在低功耗复
                                杂环境中的超远距离通信问题。 </li>
                            <li style="font-weight: bold;
                            font-size: .125rem;
                            color: #407BF5;">相较于传统调制技术</li>
                            <li>IF400TU模块在抑制同频干扰的性能方面也具有明显优势，解决了传统设计方案无法同时
                                兼顾距离、抗扰和功耗的弊端。</li>
                        </ul>
                        <div style="margin-top: .1563rem;float: left;">
                            <div style="font-size: .0729rem;
                    color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>
                    </div>

                </div>
                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: 3px solid #407BF5;width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>

                    <div
                        style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

                        <div
                            style="width: 6.8646rem;height: 6.5417rem;border: .0052rem dashed #ccc;margin-top: .1563rem;">
                            <img style="width: 100%;height: 100%;" src="../../assets/kq3.png" alt="">
                        </div>

                    </div>

                </div>
            </div>








            <div v-if="activeTab === 'page2'" style=" margin: 0 auto;">
                <div style="display: flex;width: 6.5104rem;  margin: 0 auto;justify-content: space-between;">


                    <div style="width:2.5rem;height: 2.0104rem;"> <img style="width: 100%;height:100%;"
                            src="../../assets/yu1.png" alt=""></div>

                    <div style="width: 3.8281rem;color: #407BF5;">

                        <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;">
                                433/868/915MHz通信模组</li>
                            <li style="font-weight: bold;">SI4463芯片模块</li>
                            <li style="font-weight: 500;
                            font-size: .0938rem;
                            color: #37405E;">频率范围：433/868/915MHz频段</li>
                            <li style="width: 2.3385rem;height: .849rem;"><img src="../../assets/yuo2.png"
                                    style="width: 100%;height: 100%;" alt=""></li>
                            <li style="font-weight: bold;">产品特性</li>

                            <div style="display: flex;justify-content: space-between;text-decoration: none;color: #37405E;">
                                <ul style="list-style: none;">
                                    <li><span style="color: #407BF5;">◆</span> 接受灵敏度可达-126dBm</li>
                                    <li><span style="color: #407BF5;">◆</span> 体积小 低功耗 远距离</li>
                                    <li><span style="color: #407BF5;">◆</span> 空中速率100bps-1Mbps可调</li>
                                </ul>
                                <ul style="list-style: none;">
                                    <li><span style="color: #407BF5;">◆</span> 输出功率通过寄存器配置可达+20dBm</li>
                                    <li><span style="color: #407BF5;">◆</span>空旷地带传输距离可达1500m</li>
                                    <li><span style="color: #407BF5;">◆</span> 宽电压,电压范围DC 1.8V-3.6V</li>
                                </ul>
                            </div>
                        </ul>

                        <div style="margin-top: .1563rem;;float: left;">
                            <div style="font-size: .0729rem;
                             color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>
                    </div>
                </div>

                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>

                    <div
                        style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

                        <div style="width:6.8802rem;height:3.6719rem;margin-top: .1302rem;">
                            <img style="width: 100%;height: 100%;" src="../../assets/yuo3.png" alt="">
                        </div>
                    </div>

                </div>
            </div>


            <div v-if="activeTab === 'page3'" style=" width: 6.5104rem;margin: 0 auto;">
                <div style="display: flex; justify-content: space-between;">
                    <div style="width:2.5rem;height: 2.0104rem">
                        <img style="width: 100%;height:100%;scale: 0.80;transform: translateY(.2604rem);" src="../../assets/lop.png" alt="">
                    </div>
                    <div style="width: 3.8281rem;color: #407BF5;">

                        <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;color: #37405E;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">
                                4G LTE数传模组 </li>
                            <li style="font-weight: bold;color: #407BF5;">功能说明</li>
                            <li style="height: .625rem;font-size: .0938rem;
                            color: #37405E;">IF-4G是丰海科技推出的新一代LTE
                                Cat.1无线通信模组，最大上行速率支持5Mb/s，最大下行速率支持10Mb/s；模块具有出色的射频性能，支持低功耗和超宽工作温度范围，工作温度-40°C~+85°C；模块支持国内全网通，为您提供全运营商稳定可靠通信；内置了丰富的网络协议（TCP/UDP/MQTT/PPP/NTP/FTP/HTTP/HTTPS/SSL/TLS），集成多个工业标准接口，支持丰富外设扩展。
                            </li>
                            <li style="font-weight: bold;color: #407BF5;">主要优势</li>

                           



                            <li><span style="color: #407BF5;">◆</span> 支持LTE Cat.1，最大下行速率可达10Mbps;</li>
                            <li><span style="color: #407BF5;">◆</span> 支持 GSM/LTE Cat1网络制式;</li>
                            <li><span style="color: #407BF5;">◆</span> 支持 BT4.2，支持 WIFlScan(可选);</li>
                            <li>
                                <span style="color: #407BF5;">◆</span> 支持FOTA远程升级:
                            </li>
                            <li><span style="color: #407BF5;">◆</span> 丰富的多媒体接口，满足智能场景需求;</li>
                            <li>
                                <span style="color: #407BF5;">◆</span> 超高性价比
                            </li>
                        </ul>

                        <div style="margin-top: .1563rem;float: left;">

                            <div style="font-size: .0729rem;
                      color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>

                    </div>
                </div>





                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>

                    <div style="  margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

                        <div style="width:6.875rem;height:3.5rem;margin-top: .1302rem;border: .0052rem dashed #ccc;">
                            <img style="width: 100%;height: 100%;" src="../../assets/pol1.png" alt="">
                        </div>


                    </div>

                </div>
            </div>

            <div v-if="activeTab === 'page4'" style=" width: 6.5104rem;margin: 0 auto;">
                <div style="display: flex; justify-content: space-between;">

                    <div style="width:2.5rem;height: 2.0104rem;">
                        <img style="width: 100%;height:100%;scale: 0.80;transform: translateY(.7813rem);" src="../../assets/lkp1.png" alt="">
                    </div>





                    <div style="width: 3.8281rem;color: #407BF5;">

                        <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;color: #37405E;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">
                                LoRa透传模组
                            </li>
                            <li style="color:#407BF5;font-weight: bold;">功能说明</li>
                            <li style="height: .625rem;">
                                IF-LoRa
                                透传模组是丰海科技研制的一款基于LoRa的无线通信透传模组。模组采用串行接口与用户设备进行数据、指令交互，可以方便地为用户提供点对点和点对多无线通信等业务。本模组支持持续休眠、周期性休眠和活跃状态三种功耗状态，用户可以根据自己的应用场景选择合适的功耗状态。

                            </li>
                            <li style="color: #407BF5;font-weight: bold;">产品特点</li>

                            <li style="color: #407BF5;font-size: .0729rem;font-weight: bold;"> 工作频段</li>
                            <li> <span style="color: #407BF5;">◆</span> 430～435MHz、470～510MHz、860～930MHz</li>
                            <li style="color: #407BF5;font-size: .0729rem;font-weight: bold;">高链路预算</li>
                            <li> <span style="color: #407BF5;">◆</span> 接收灵敏度（典型值）：-124dBm@SF7_BW125KHz</li>
                            <li> <span style="color: #407BF5;">◆</span>发射功率 Max. 22 dBm</li>
                            <li style="color: #407BF5;font-size: .0729rem;font-weight: bold;"> 通信接口</li>
                            <li><span style="color: #407BF5;">◆</span> UART</li>
                            <li style="color: #407BF5;font-size: .0729rem;font-weight: bold;">工作电源</li>
                            <li> <span style="color: #407BF5;">◆</span>工作电压：DC3.3V(典型值)</li>
                            <li> <span style="color: #407BF5;">◆</span>发射电流：95mA@TX power_22dBm （430～435MHz）
                                55mA@TX power_17dBm （470～510MHz）
                                120mA@TX power_22dBm（860～930MHz）</li>
                        </ul>

                        <div style="margin-top: .1563rem;float: left;">
                            <div style="font-size: .0729rem;
                      color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>
                    </div>
                </div>

                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>

                    <div style="  margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

                        <div style="width:6.875rem;height:5.3281rem;margin-top: .1302rem;border: .0052rem dashed #ccc;">
                            <img style="width: 100%;height: 100%;" src="../../assets/qjj1.png" alt="">
                        </div>


                    </div>

                </div>
            </div>



            <div v-if="activeTab === 'page5'" style=" width: 6.5104rem;margin: 0 auto;">
                <div style="display: flex; justify-content: space-between;">

                    <div style="width:2.5rem;height: 2.0104rem;">
                        <img style="width: 100%;height:100%;scale: 0.80;" src="../../assets/liio1.png" alt="">
                    </div>





                    <div style="width: 3.8281rem;color: #407BF5;">

                        <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;color: #37405E;">
                            <li
                                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">
                                蓝牙模组
                            </li>
                            <li style="color: #407BF5;font-weight: bold;font-size: .0938rem;">功能说明</li>

                            <li style="height: .625rem;">
                                IF-BLE260 系列是低功耗、高性能的串口转 BLE 低功耗蓝牙模块，采用最先进的 Bluetooth
                                Specification V4.2 协议栈，支持大数据收发，支持主从一体。是一款完全集成的模块，包括
                                所有所需的时钟，SPI Flash 和无源器件。专门为物联网应用而设计，它可以让物联网设备，
                                嵌入式系统轻松连接智能移动终端，实现数据的传输与控制。
                            </li>

                        </ul>

                        <div style="margin-top: .1563rem;float: left;">
                            <div style="font-size: .0729rem;
                        color: #2056c0;float: left;margin-bottom: .026rem;">
                                <icon class="el-icon-phone-outline"></icon>技术热线
                            </div>
                            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
                        </div>
                    </div>
                </div>

                <div>


                    <p
                        style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
                        应用场景
                    </p>

                    <div style="  margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

                        <div style="width:6.875rem;height:3.2448rem;margin-top: .1302rem;border: .0052rem dashed #ccc;">
                            <img style="width: 100%;height: 100%;" src="../../assets/loi1.png" alt="">
                        </div>


                    </div>

                </div>
            </div>
        </div>

        <div
            style="background-color: #f4f4f4; width: 100%; padding-top: .1563rem; box-sizing: border-box;margin-top: .1563rem;">
            <p
                style="font-size: .1667rem; height: .1563rem; width: 6.5104rem; text-align: left; margin: 0 auto; color:#407bf5; font-family: PingFang SC;font-weight: bold;">
                产品列表
            </p>

            <!-- 产品展示区 -->
            <div style="width: 6.5104rem; margin: 0 auto; margin-top: .1563rem; overflow: hidden;">
                <div style="display: flex; transition: transform 0.3s ease-in-out;"
                    :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                    <div v-for="(page, pageIndex) in paginatedProducts" :key="pageIndex"
                        style="display: flex; flex-shrink: 0; width: 100%;height: 100%;">
                        <div v-for="(item, index) in page" :key="index"
                            style="width: 2.0833rem; height: 2.0833rem; background-color: #FFF; margin-left: .1042rem;">
                            <img style="width: 100%; height: 100%;" :src="item.img" alt="">
                            <p style="text-align: center; font-size: .0625rem;transform: translateY(-.1563rem);">{{
                                item.name }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 分页指示器 -->
            <div style="display: flex; justify-content: center; margin-top: .0521rem;">
                <span v-for="(dot, index) in paginatedProducts.length" :key="index" @click="goToPage(index)"
                    style="width: .0417rem; height: .0417rem; border-radius: 50%; margin: 0 .026rem; cursor: pointer;"
                    :style="{ backgroundColor: index === currentIndex ? ' #407BF5' : '#ccc' }">
                </span>
            </div>
        </div>



        <!-- 返回按钮 -->
        <div
            style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;font-weight: bold;">
            <el-button @click="goBack">返回列表</el-button>
        </div>

    </div>
</template>


<script>
    export default {
        data() {
            return {
                activeTab: "page1", // 默认显示页面1
                products: [
                    
                    { name: "433/868/915MHz通信模组", img: require("@/assets/kq6.png") },
                    { name: "4G LTE数传模组", img: require("@/assets/kq5.png") },
                    { name: "LoRa透传模组", img: require("@/assets/kq7.png") },
                    { name: "蓝牙模组", img: require("@/assets/tx7.png") },
                ],
                itemsPerPage: 3,
                currentIndex: 0

            };
        },

        created() {
    // 读取 URL 参数，并设置 activeTab
    if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
    }},
        
        computed: {
            paginatedProducts() {
                return this.products.reduce((result, item, index) => {
                    const pageIndex = Math.floor(index / this.itemsPerPage);
                    if (!result[pageIndex]) result[pageIndex] = [];
                    result[pageIndex].push(item);
                    return result;
                }, []);
            }
        },
        methods: {
            goToPage(index) {
                this.currentIndex = index;
            },
            goBack() {
                this.$router.push({ path: "/civilianProducts", query: { tab: 'second' } });
                const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - windowHeight / 2; // 计算滚动距离  
                window.scrollTo(0, scrollTo); // 滚动到目标位置
            },
            switchPage(tab) {
                this.activeTab = tab; // 切换页面
            },
        },
    };
</script>


<style scoped>
    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .myUl li {
        
        margin-top: .0625rem;
    }

    /* 样式调整 */
    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;
        text-align: center;
    }

    h3 {
        font-size: 1rem;
        color: #2056c0;
    }

    p {
        font-size: 0.875rem;
        color: #666;
    }
</style>