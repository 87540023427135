<template>
  <div>
    <!-- 面包屑导航 -->
    <div style="width: 6.5104rem; margin: 0 auto;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/civilianProducts', query: { tab: 'first' }}">雷达传感器</el-breadcrumb-item>
        <el-breadcrumb-item>24GHz系列</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- 按钮切换 -->
    <div style="display: flex; justify-content: left; width: 6.5104rem;margin: 0 auto;margin-top: .1042rem;">

      <el-button
        :style="{ backgroundColor: activeTab === 'page1' ? '#2056c0' : '' ,   color: activeTab === 'page1' ? '#fff' : '' }"
        @click="switchPage('page1')">
        24GHz雷达模块
      </el-button>

      <el-button
        :style="{ backgroundColor: activeTab === 'page2' ? '#2056c0' : '' ,  color: activeTab === 'page2' ? '#fff' : '' }"
        @click="switchPage('page2')">
        24GHz低功耗雷达模块
      </el-button>

      <el-button
        :style="{ backgroundColor: activeTab === 'page3' ? '#2056c0' : '' ,  color: activeTab === 'page3' ? '#fff' : '' }"
        @click="switchPage('page3')">
        24GHz窄边雷达模块
      </el-button>

      <el-button
        :style="{ backgroundColor: activeTab === 'page4' ? '#2056c0' : '' ,  color: activeTab === 'page4' ? '#fff' : '' }"
        @click="switchPage('page4')">
        24GHz人体存在雷达
      </el-button>
    </div>

    <!-- 页面内容显示 -->
    <div class="content">

      <div v-if="activeTab === 'page1'" style="margin: 0 auto;">
        <div style="display: flex;width: 6.5104rem; margin: 0 auto;justify-content: space-between;">
          <div style="width:2.5rem;height: 1.4583rem;">
            <img style="width: 100%;height:100%;" src="../../assets/qo1.png" alt="">
          </div>
          <div style="width: 3.8281rem;color: #407BF5;">
            <ul class="myUl"
              style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;font-size: .0938rem;color: #37405E;">
              <li
                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">
                24GHz雷达模块 </li>
              <li><span style="color: #407BF5;">◆</span> 模块集成了 1 发 2 收（1T2 R）贴片天线、CortexM0+ MCU 和配套的 UART 接口，并通过内置 FLASH
                组成了完整 的 SoC 系统；</li>
              <li><span style="color: #407BF5;">◆</span>基于FMCW（连续调频连续波）雷达技术，可实现对运动和微动目标的检测；</li>
              <li><span style="color: #407BF5;">◆</span>嵌入多重数字滤波器算法，稳定度更高，抗干扰更强，稳定输出高低电平控制信号；</li>
              <li><span style="color: #407BF5;">◆</span> 家居环境的空间覆盖，可独立完成感知；商业环境的检测，可多个组合完成感知；</li>
              <li><span style="color: #407BF5;">◆</span> 可穿透亚克力、玻璃及薄的非金属材料不受温度、湿度、气流、灰尘、噪声、亮暗、等环境影响，抗干扰能力强。</li>
            </ul>
            <div style="margin-top: .1563rem;float: left;">
              <div style="font-size: .0729rem;
                  color: #2056c0;float: left;margin-bottom: .026rem;">
                <icon class="el-icon-phone-outline"></icon>技术热线
              </div>
              <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
            </div>
          </div>

        </div>
        <div>


          <p
            style="font-size: .0938rem;height: .1823rem ; border-bottom: 3px solid #407BF5;width:  6.5104rem;text-align: left;margin: 0 auto;">
            应用场景
          </p>

          <div
            style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

            <div style="width: 6.875rem;height: 5.3333rem;margin-top: .1563rem;margin-bottom: .1563rem;">
              <img style="width: 100%;height: 100%;" src="../../assets/of.png" alt="">
            </div>

          </div>

        </div>
      </div>








      <div v-if="activeTab === 'page2'" style=" margin: 0 auto;">
        <div style="display: flex;width: 6.5104rem;  margin: 0 auto;justify-content: space-between;">


          <div style="width:2.5rem;height: 1.4583rem;"> <img style="width: 100%;height:100%;" src="../../assets/io.png"
              alt=""></div>

          <div style="width: 3.8281rem;color: #407BF5;">

            <ul class="myUl"
              style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;font-size: .0938rem;color: #37405E;">
              <li
                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">
                24GHz低功耗雷达模块</li>
              <li><span style="color: #407BF5;">◆</span> 模块采用高性能雷达传感器配合小型化平面天线，在保证最佳传感器性能的同时将尺寸做到20*11mm；</li>
              <li><span style="color: #407BF5;">◆</span> 基于FMCW（连续调频连续波）雷达技术，触发距离稳定，触发灵敏，可实现对运动和微动目标的高精度检测；</li>
              <li><span style="color: #407BF5;">◆</span> 嵌入多重数字滤波器算法，稳定度更高，抗干扰更强，稳定输出高低电平控制信号；</li>
              <li><span style="color: #407BF5;">◆</span>
                可用于检测人体存在或移动目标感应的各种场景，包括智能家居、智能门铃、智能门锁等，特别适用于小夜灯、太阳能路灯及无线摄像头等由电池供电的低功耗场景；</li>
              <li><span style="color: #407BF5;">◆</span> 可穿透亚克力、玻璃及薄的非金属材料不受温度、湿度、气流、灰尘、噪声、亮暗、等环境影响，抗干扰能力强；</li>
              <li><span style="color: #407BF5;">◆</span> 产品尽量避免将雷达天线方向正对大型金属设备或管道等。</li>
            </ul>

            <div style="margin-top: .1563rem;float: left;">
              <div style="font-size: .0729rem;
                  color: #2056c0;float: left;margin-bottom: .026rem;">
                <icon class="el-icon-phone-outline"></icon>技术热线
              </div>
              <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
            </div>
          </div>
        </div>

        <div>


          <p
            style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
            应用场景
          </p>

          <div
            style=" margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

            <div style="width:6.8802rem;height:3.776rem;margin-top: .1302rem;">
              <img style="width: 100%;height: 100%;" src="../../assets/lp.png" alt="">
             
            </div>
            <div style="width:6.8802rem;height:4.8438rem;margin-top: .1302rem;margin-bottom: .0781rem;">
              <img style="width: 100%;height: 100%;" src="../../assets/wpo2.png" alt="">
             
            </div>
          </div>

        </div>
      </div>


      <div v-if="activeTab === 'page3'" style=" width: 6.5104rem;margin: 0 auto;">
        <div style="display: flex; justify-content: space-between;">
          <div style="width:2.5rem;height: 1.4583rem">
            <img style="width: 100%;height:100%;" src="../../assets/lpoi1.png" alt="">
          </div>
          <div style="width: 3.8281rem;color: #407BF5;">

            <ul class="myUl"
              style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;font-size: .0938rem;color: #37405E;">
              <li
                style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">24GHz窄边雷达模块 </li>
              <li><span style="color: #407BF5;">◆</span> 嵌入多重数字滤波器算法，稳定度更高，抗干扰更强，稳定输出高低电平控制信号；</li>
              <li><span style="color: #407BF5;">◆</span>家居环境的空间覆盖，可独立完成感知；商业环境的检测，可多个组合完成感知；</li>
              <li><span style="color: #407BF5;">◆</span> 可穿透亚克力、玻璃及薄的非金属材料不受温度、湿度、气流、灰尘、噪声、亮暗、等环境影响，抗干扰能力强；</li>
              <li><span style="color: #407BF5;">◆</span> 该模组适用于狭小空间，模块大小为49mm*7mm</li>
            </ul>

            <div style="margin-top: .1563rem;float: left;">
              <div style="font-size: .0729rem;
                    color: #2056c0;float: left;margin-bottom: .026rem;">
                <icon class="el-icon-phone-outline"></icon>技术热线
              </div>
              <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
            </div>


          </div>
        </div>





        <div>


          <p
            style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
            应用场景
          </p>

          <div style="  margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

            <div style="width:6.875rem;height:3.776rem;margin-top: .1302rem;border: .0052rem dashed #ccc;">
              <img style="width: 100%;height: 100%;" src="../../assets/ml1.png" alt="">
            </div>

            <div style="width:6.875rem;height:3.3646rem;margin-top:.1563rem;border: .0052rem dashed #ccc;">
              <img style="width: 100%;height: 100%;" src="../../assets/ml2.png" alt="">
            </div>
          </div>

        </div>
      </div>

      <div v-if="activeTab === 'page4'" style=" width: 6.5104rem;margin: 0 auto;">
        <div style="display: flex; justify-content: space-between;">

          <div style="width:2.5rem;height: 1.4583rem;">
            <img style="width: 100%;height:100%;" src="../../assets/ep1.png" alt="">
          </div>





          <div style="width: 3.8281rem;color: #407BF5;">

            <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;font-size: .0938rem;color: #37405E;">
              <li style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;color: #407BF5;">
                24GHz人体存在雷达
              </li>
              <li><span style="color: #407BF5;">◆</span>基于FMCW（连续调频连续波）雷达技术，可实现对运动和微动目标的检测；</li>
              <li> <span style="color: #407BF5;">◆</span>模块可进行单目标与多目标检测，探测角度可达120°，最远探测距离可达5.4m以上；</li>
              <li> <span style="color: #407BF5;">◆</span>模块输出数据格式为串口ASCII输出，数据刷新率为10Hz；</li>
              <li> <span style="color: #407BF5;">◆</span> 工作频段需满足欧盟CE认证、北美FCC认证要求；</li>
              <li> <span style="color: #407BF5;">◆</span>
                本产品主要应用在室内场景，感应距离与分辨率均可通过配置工具进行配置，支持GPIO和UART等输出，即插即用，可灵活应用于不同的智能场景和终端产品。</li>
            </ul>

            <div style="margin-top: .1563rem;float: left;">
              <div style="font-size: .0729rem;
                    color: #2056c0;float: left;margin-bottom: .026rem;">
                <icon class="el-icon-phone-outline"></icon>技术热线
              </div>
              <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
            </div>
          </div>
        </div>

        <div>


          <p
            style="font-size: .0938rem;height: .1823rem ; border-bottom: .0156rem solid #407BF5; width:  6.5104rem;text-align: left;margin: 0 auto;">
            应用场景
          </p>

          <div style="  margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">

            <div style="width:6.875rem;height:3.776rem;margin-top: .1302rem;border: .0052rem dashed #ccc;">
              <img style="width: 100%;height: 100%;" src="../../assets/ml3.png" alt="">
            </div>

            <div style="width:6.875rem;height:5.2448rem;margin-top:.1563rem;border: .0052rem dashed #ccc;">
              <img style="width: 100%;height: 100%;" src="../../assets/ml4.png" alt="">
            </div>
          </div>

        </div>
      </div>
    </div>

    <div style="background-color: #f4f4f4; width: 100%; padding-top: .1563rem; box-sizing: border-box;">
      <p
        style="font-size: .1667rem; height: .1563rem; width: 6.5104rem; text-align: left; margin: 0 auto; color:#407bf5; font-family: PingFang SC;font-weight: bold;">
        产品列表
      </p>

      <!-- 产品展示区 -->
      <div style="width: 6.5104rem; margin: 0 auto; margin-top: .1563rem; overflow: hidden;">
        <div style="display: flex; transition: transform 0.3s ease-in-out;"
          :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
          <div v-for="(page, pageIndex) in paginatedProducts" :key="pageIndex"
            style="display: flex; flex-shrink: 0; width: 100%;">
            <div v-for="(item, index) in page" :key="index"
              style="width: 2.0833rem; height: 2.0833rem; background-color: #FFF; margin-left: .1042rem;">
              <img style="width: 100%; height: 100%;" :src="item.img" alt="">
              <p style="text-align: center; font-size: .0625rem;transform: translateY(-.1563rem);">{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页指示器 -->
      <div style="display: flex; justify-content: center; margin-top: .0521rem;">
        <span v-for="(dot, index) in paginatedProducts.length" :key="index" @click="goToPage(index)"
          style="width: .0417rem; height: .0417rem; border-radius: 50%; margin: 0 .026rem; cursor: pointer;"
          :style="{ backgroundColor: index === currentIndex ? ' #407BF5' : '#ccc' }">
        </span>
      </div>
    </div>



    <!-- 返回按钮 -->
    <div
      style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;font-weight: bold;">
      <el-button @click="goBack">返回列表</el-button>
    </div>

  </div>
</template>


<script>
  export default {
    data() {
      return {
        activeTab: "page1", // 默认显示页面1
        products: [
          { name: "24GHz雷达模块", img: require("@/assets/uo1.png") },
          { name: "24GHz低功耗雷达模块", img: require("@/assets/uo2.png") },
          { name: "24GHz窄边雷达模块", img: require("@/assets/uo3.png") },
          { name: "24GHz人体存在雷达", img: require("@/assets/uo4.png") },
        ],
        itemsPerPage: 3,
        currentIndex: 0

      };
    },
    computed: {
      paginatedProducts() {
        return this.products.reduce((result, item, index) => {
          const pageIndex = Math.floor(index / this.itemsPerPage);
          if (!result[pageIndex]) result[pageIndex] = [];
          result[pageIndex].push(item);
          return result;
        }, []);
      }
    },
    methods: {
      goToPage(index) {
        this.currentIndex = index;
      },
      goBack() {
        this.$router.push({ path: "/civilianProducts", query: { tab: 'first' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - windowHeight / 2; // 计算滚动距离  
        window.scrollTo(0, scrollTo); // 滚动到目标位置
      },
      switchPage(tab) {
        this.activeTab = tab; // 切换页面
      },
    },
  };
</script>


<style scoped>
  .tel {
    padding-top: .0521rem;
    font-size: .1875rem;
    color: #bb3944;
    font-family: Impact;
  }

  .myUl li {
    height: .2344rem;
    margin-top: .0625rem;
  }

  /* 样式调整 */
  .content {
    padding-top: .1563rem;
    font-size: .0729rem;
    line-height: .125rem;
    color: #434343;
    text-align: center;
  }

  h3 {
    font-size: 1rem;
    color: #2056c0;
  }

  p {
    font-size: 0.875rem;
    color: #666;
  }
</style>