<template>
    <div>
      <!-- 面包屑导航 -->
      <div style="width: 6.5104rem; margin: 0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center'}">产品中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/civilianProducts', query: { tab: 'first' }}">雷达传感器</el-breadcrumb-item>
          <el-breadcrumb-item>10.525GHz系列</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  
      <!-- 按钮切换 -->
      <div style="display: flex; justify-content: left; width: 6.5104rem;margin: 0 auto;margin-top: .1042rem;">
  
        <el-button
          :style="{ backgroundColor: activeTab === 'page1' ? '#2056c0' : '', color: activeTab === 'page1' ? '#fff' : '' }"
          @click="switchPage('page1')">
          10.525GHz雷达模块
        </el-button>
<!--   
        <el-button
          :style="{ backgroundColor: activeTab === 'page2' ? '#2056c0' : '' , color: activeTab === 'page2' ? '#fff' : '' }"
          @click="switchPage('page2')">
          5.8GHz低功耗雷达
        </el-button> -->
      </div>
  
      <!-- 页面内容显示 -->
      <div class="content">
        <div v-if="activeTab === 'page1'" style=" width: 6.5104rem;margin: 0 auto;">
          <div style="display: flex; justify-content: space-between;">
              
            <div style="width:2.5rem;height: 1.4583rem;">
                <img style="width: 100%;height:100%;" src="../../assets/lo1.png" alt="">
              </div>
              
              <div style="width: 3.8281rem;color: #407BF5;">
                
                <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;font-size: .0938rem;color: #37405E;">
                  <li style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070; color: #407BF5;"> 10.525GHz雷达模块 </li> 
                  <li><span style="color: #407BF5;">◆</span>模块是一款基于X波段雷达芯片而设计的运动感知模组，中心频率为10.525GHz；</li>
                  <li><span style="color: #407BF5;">◆</span> 此产品具备穿墙、抗干扰、体积小、高稳定和一致性等优点；</li>
                  <li><span style="color: #407BF5;">◆</span> 模块具备感光检测的能力，可根据客户需求进行配置；</li>
                  <li><span style="color: #407BF5;">◆</span> 模块初始化时间短，同时具备快检模式，安装在灯具中测试操作简单，主要针对低成本应用领域；</li>
                  <li><span style="color: #407BF5;">◆</span> 该产品适合嵌入式隐蔽安装，不受温湿度、油烟、水雾等影响，可广泛应用于各类灯具，如球泡灯、筒灯、吸顶灯等。</li>
                </ul>

              <div style="margin-top: .1563rem;float: left;">
                  <div style="font-size: .0729rem;
                  color: #2056c0;float: left;margin-bottom: .026rem;">
                      <icon class="el-icon-phone-outline"></icon>技术热线
                  </div>
                  <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
              </div>
            </div>
          </div>
        </div>
  
        <div v-else  style=" width: 6.5104rem;margin: 0 auto;">
          <div style="display: flex; justify-content: space-between;">
              
            <div style="width: 1.8021rem;height: 2.1094rem;"> <img style="width: 100%;height:100%;" src="../../assets/mycp1.png" alt=""></div>
             
            <div style="width: 3.8281rem;color: #407BF5;">
              
              <ul class="myUl" style="width: 100%; text-align: left;list-style: none;font-size: .0938rem;">
                <li style="font-size: .1667rem;font-weight: bold;height:.2604rem;border-bottom: 1px solid #707070;"> 5.8GHz雷达模块</li> 
                  <li>◆ 模块采用高性能雷达传感器配合小型化平面天线，在保证最佳传感器性能的同时将尺寸做到22*11mm。</li>
                  <li>◆ 基于多普勒原理，可实现对运动和微动目标的检测。</li>
                  <li>
                      ◆ 嵌入多重数字滤波器算法，稳定度更高，抗干扰更强，稳定输出高低电平控制信号。</li>
                  <li>◆ 可用于检测人体存在或移动目标感应的各种场景，包括智能家居、智能门铃、智能门锁等，特别适用于小夜灯、太阳能路灯及无线摄像头等由电池供电的低功耗场景。</li>
                  <li>◆ 可穿透亚克力、玻璃及薄的非金属材料不受温度、湿度、气流、灰尘、噪声、亮暗、等环境影响，抗干扰能力强。</li>
              </ul>
  
              <div style="margin-top: .1563rem;float: left;">
                  <div style="font-size: .0729rem;
                  color: #2056c0;float: left;margin-bottom: .026rem;">
                      <icon class="el-icon-phone-outline"></icon>技术热线
                  </div>
                  <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <p style="font-size: .0938rem;height: .1823rem;border-bottom: 3px solid #407BF5;width:  6.5104rem;text-align: left;margin: 0 auto;">应用场景</p>
      <div style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;width: 6.5104rem; margin: 0 auto;">
          <div style="width: 6.7292rem;height: 4.5833rem;margin-top: .1563rem;">
              <img style="width: 100%;height: 100%;" src="../../assets/wpo1.png" alt="">
          </div>
      </div>
  
  
        <div style="background-color: #f4f4f4;width: 100%;height: 3.0417rem;padding-top: .1563rem;box-sizing: border-box;">
          <p style="font-size: .1667rem;height: .1563rem;width:  6.5104rem;text-align: left;margin: 0 auto;color:#407bf5 ;font-family: PingFang SC, PingFang SC;font-weight: bold;">产品列表</p>
        <div style="width: 6.5104rem;height: 2.0833rem;margin: 0 auto;margin-top: .1563rem;display: flex;justify-content: left;">
          <div style="width: 2.0833rem;height: 100%;background-color: #FFF;">
              <img  style="width: 100%;height: 100%;" src="../../assets/kp.png" alt="">
              <p style="text-align: center;font-size: .0625rem;"> 10.525GHz雷达模块 </p>
         </div>
  
  
          <!-- <div style="width: 2.0833rem;height: 100%;background-color: #FFF;margin-left: .1042rem;">
              <img  style="width: 100%;height: 100%;" src="../../assets/mycp4.png" alt="">
              <p style="text-align: center;font-size: .0625rem;"> 5.8GHz低功耗雷达 </p>
          </div>
   -->
        </div>
  
        </div>
  
      <!-- 返回按钮 -->
      <div style="display: flex; justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem;font-weight: bold;">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
    </div>
  </template>
  
  
  <script>
  
  export default {
    data() {
      return {
        activeTab: "page1", // 默认显示页面1
      };
    },
    methods: {
      goBack() {
        this.$router.push({ path: "/civilianProducts", query: { tab: 'first' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - windowHeight / 2; // 计算滚动距离  
        window.scrollTo(0, scrollTo); // 滚动到目标位置
      },
      switchPage(tab) {
        this.activeTab = tab; // 切换页面
      },
    },
  };
  
  </script>
  
  
  <style scoped>
  .tel {
          padding-top: .0521rem;
          font-size: .1875rem;
          color: #bb3944;
          font-family: Impact;
      }
  
  .myUl li{
          height:.2344rem;
          margin-top: .0625rem;
      }
  /* 样式调整 */
  .content {
    padding-top: .1563rem;
    font-size: .0729rem;
    line-height: .125rem;
    color: #434343;
    text-align: center;
  }
  
  h3 {
    font-size: 1rem;
    color: #2056c0;
  }
  
  p {
    font-size: 0.875rem;
    color: #666;
  }
  </style>
  
  